import React from "react";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default function ImageViewer({ callback, src }) {
  return (
    <div
      style={{
        position: "fixed",
        left: -40,
        top: -16,
        zIndex: 2000,
        width: "100%",
        height: "100%",
      }}
    >
      <Button
        variant="contained"
        onClick={callback}
        style={{
          backgroundColor: '#eee',
          position: "fixed",
          right: "35px",
          top: "55px",
          marginLeft: "20px",
          color: "#000",
        }}
        edge="end"
      >
        <CancelIcon />
      </Button>
      <figure
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0, .8)",
          display: "flex",
          overflow: "auto",
          padding: "25px",
        }}
      >
        <img
          src={src}
          alt="Renter document"
          style={{ margin: "auto", maxWidth: "80%" }}
        />
      </figure>
    </div>
  );
}
