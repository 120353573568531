import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { endpoints } from "../../../../constants/Endpoints";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import ProgressIndicator from "../../../ProgressIndicator";

function PhotoGallery(props) {
  const [images, setImages] = useState(null);

  const getImages = async () => {
    if (!images) {
      var fetchedImages = [];

      for (var index in props.files) {
        const response = await HttpClient.doRequest(Methods.POST, endpoints.lead.getImage, {
          id: props.files[index].id,
          trustedFileNameForFileStorage:
            props.files[index].trustedFileNameForFileStorage,
        })

        const blob = await response.blob();
        var uri = URL.createObjectURL(blob);

        fetchedImages.push({
          src: uri,
          thumbnail: uri,
          // width: 4,
          // height: 4,
        });
      }

      setImages(fetchedImages);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return images ? (
    <Gallery
      images={images}
      enableImageSelection={false}
      // thumbnailImageComponent={ImageComponent}
      tileViewportStyle={{ height: "auto", width: "auto", maxWidth: '200px' }}
      thumbnailStyle={{ height: "auto", width: "auto", maxWidth: '200px' }}
      tagStyle={{ display: "none" }}
    />
  ) : (
    <ProgressIndicator />
  );
}

export default PhotoGallery;