// File categories are for frontend grouping.
export const fileCategories = {
  identification: 0,
  financialDetails: 1,
  guarantor: 2,
  additional: 3,
  rentalPriceCalculationImage: 4,
  rentalPriceCalculationFloorPlan: 5,
};

// Order of filetypes is based on the enumeration of the backend.
export const fileTypes = [
  "identificationFront",
  "identificationBack",
  "laborContract",
  "salarySlipMonth1",
  "salarySlipMonth2",
  "salarySlipMonth3",
  "guarantorIdentificationFront",
  "guarantorIdentificationBack",
  "guarantorGuaranteeForm",
  "guarantorLaborContract",
  "guarantorSalarySlipMonth1",
  "guarantorSalarySlipMonth2",
  "guarantorSalarySlipMonth3",
  "guarantorChamberOfCommerceFile",
  "guarantorProfitLossFile",
  "additionalFile1",
  "additionalFile2",
  "additionalFile3",
  "additionalFile4",
  "additionalFile5",
  "additionalFile6",
  "salarySlipMonthCoTenant1",
  "salarySlipMonthCoTenant2",
  "salarySlipMonthCoTenant3",
  "coTenantIdentificationFront",
  "coTenantIdentificationBack",
];
