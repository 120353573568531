import ProfileService from "./ProfileService";
import Translations from "./localization/Translations";

export const availableLocales = ["nl_NL", "en_US"];

class TranslationService {
  currentLocale;
  l;

  constructor() {
    this.currentLocale = ProfileService.getLocale() ?? availableLocales[0];
    this.l = Translations[this.currentLocale];
  }
}

export default new TranslationService();