export default {
  en_US: {
    inschrijvingen: "Registrations",
    archief: "Archive",
    woningen: "Properties",
    codellicHome: "Codellic",
    huurderProfiel: "My registration",
    verhuurderProfiel: "Mijn gegevens",
    achternaam: "Last name",
    voornaam: "First name",
    bedrijfsnaam: "Company",
    telefoonnummer: "Phone number",
    email: "Email",
    nettoInkomenPerMaand: "Net income per month",
    adres: "Address",
    postCode: "Zip code",
    toevoeging: "Addition",
    plaats: "City",
    algemeneGegevens: "General details",
    huidigAdres: "Current address",
    woonwensen: "Preferences",
    maximumPrijs: "Maximum price",
    interieur: "Interior",
    stad: "City",
    startdatumHuren: "Renting startdate",
    overigeWensen: "Other wishes",
    minimumAantalSlaapkamers: "Number of bedrooms",
    instellingen: "Settings",
    taal: "Language",
    geabonneerdOpEmailNieuwWoningaanbod: "Subscribed to new properties email",
    annuleren: "Cancel",
    opslaan: "Save",
    bewerkProfiel: "Edit profile",
    ongemeubileerd: "Unfurnished",
    gemeubileerd: "Furnished",
    leeg: "Empty",
    nederlands: "Dutch",
    engels: "English",
    logUit: "Log out",
    ingeschrevenSinds: "Registered since: ",
    mijnDocumenten: "My documents",
    legitimatie: "Identification",
    inkomensgegevens: "Financial details",
    garantSteller: "Guarantor",
    additioneleDocumenten: "Additional documents",
    huisnummer: "Number",
    identificationFront: "Identification: front",
    identificationBack: "Identification: back",
    coTenantIdentificationFront: "Co-Tenant identification: front",
    coTenantIdentificationBack: "Co-Tenant identification: back",
    laborContract: "Labor contract",
    salarySlipMonth1: "Salaryslip 1",
    salarySlipMonth2: "Salaryslip 2",
    salarySlipMonth3: "Salaryslip 3",
    salarySlipMonthCoTenant1: "Co-Tenant: salaryslip 1",
    salarySlipMonthCoTenant2: "Co-Tenant: salaryslip 2",
    salarySlipMonthCoTenant3: "Co-Tenant: salaryslip 3",
    guarantorIdentificationFront: "Guarantor identification: front",
    guarantorIdentificationBack: "Guarantor identification: back",
    guarantorGuaranteeForm: "Guarantee form",
    guarantorLaborContract: "Guarantor: Labor contract",
    guarantorSalarySlipMonth1: "Guarantor: Salaryslip 1",
    guarantorSalarySlipMonth2: "Guarantor: Salaryslip 2",
    guarantorSalarySlipMonth3: "Guarantor: Salaryslip 3",
    guarantorChamberOfCommerceFile: "Guarantor: Chamber of Commerce file",
    guarantorProfitLossFile: "Guarantor: Profit/loss account",
    additionalFile1: "Optional: other document 1",
    additionalFile2: "Optional: other document 2",
    additionalFile3: "Optional: other document 3",
    additionalFile4: "Optional: other document 4",
    additionalFile5: "Optional: other document 5",
    additionalFile6: "Optional: other document 6",
    errorTitle: "Error",
    defaultErrorDesc:
      "An error occurred while uploading the file. Check your connection or try a smaller file.",
    ingeschrevenTot: "Registered until: ",
    ingeschrevenOp: "Registered on: ",
    inschrijvingVerlopen:
      "Registration expired: Contact us to renew your registration.",
    settingsAVGTekst:
      "Do you want us to completely remove your account from our system? Please contact us by phone.",
    uitlegDocumentenUploaden:
      "Note: if you upload a document, you can only view it and not delete it. Please contact us if you want to remove a document.",
    weetJeHetzeker: "Are you sure?",
    geenVoorkeur: "No preference",
    bredaEnOmgeving: "Breda and surroundings",
    geenAparteSlaapkamer: "No seperate bedroom",
    naarWoningaanbod: "View properties",
  },
  nl_NL: {
    inschrijvingen: "Inschrijvingen",
    archief: "Archief",
    woningen: "Woningen",
    codellicHome: "Codellic",
    huurderProfiel: "Mijn inschrijving",
    verhuurderProfiel: "Mijn gegevens",
    achternaam: "Achternaam",
    voornaam: "Voornaam",
    bedrijfsnaam: "Bedrijfsnaam",
    telefoonnummer: "Telefoonnummer",
    email: "Email",
    nettoInkomenPerMaand: "Netto inkomen per maand",
    adres: "Adres",
    postCode: "Postcode",
    toevoeging: "Toevoeging",
    plaats: "Plaats",
    algemeneGegevens: "Algemene gegevens",
    huidigAdres: "Huidig adres",
    woonwensen: "Woonwensen",
    maximumPrijs: "Maximum prijs",
    interieur: "Interieur",
    stad: "Stad",
    startdatumHuren: "Startdatum huren",
    overigeWensen: "Overige wensen",
    minimumAantalSlaapkamers: "Aantal slaapkamers",
    instellingen: "Instellingen",
    taal: "Taal",
    geabonneerdOpEmailNieuwWoningaanbod:
      "Geabonneerd op e-mail nieuw woningaanbod",
    annuleren: "Annuleren",
    opslaan: "Opslaan",
    bewerkProfiel: "Bewerk profiel",
    ongemeubileerd: "Ongemeubileerd",
    gemeubileerd: "Gemeubileerd",
    leeg: "Leeg",
    nederlands: "Nederlands",
    engels: "Engels",
    logUit: "Uitloggen",
    ingeschrevenSinds: "Ingeschreven sinds: ",
    mijnDocumenten: "Mijn documenten",
    legitimatie: "Legitimatie",
    inkomensgegevens: "Inkomensgegevens",
    garantSteller: "Garantsteller",
    additioneleDocumenten: "Additionele documenten",
    huisnummer: "Nummer",
    identificationFront: "Legitimatie: voorkant",
    identificationBack: "Legitimatie: achterkant",
    coTenantIdentificationFront: "Medehuurder legitimatie: voorkant",
    coTenantIdentificationBack: "Medehuurder legitimatie: achterkant",
    laborContract: "Arbeidsovereenkomst",
    salarySlipMonth1: "Salarisstrook 1",
    salarySlipMonth2: "Salarisstrook 2",
    salarySlipMonth3: "Salarisstrook 3",
    salarySlipMonthCoTenant1: "Medehuurder salarisstrook 1",
    salarySlipMonthCoTenant2: "Medehuurder salarisstrook 2",
    salarySlipMonthCoTenant3: "Medehuurder salarisstrook 3",
    guarantorIdentificationFront: "Garantsteller legitimatie: voorkant",
    guarantorIdentificationBack: "Garantsteller legitimatie: achterkant",
    guarantorGuaranteeForm: "Garantstellingsformulier",
    guarantorLaborContract: "Garantsteller: Arbeidsovereenkomst",
    guarantorSalarySlipMonth1: "Garantsteller: Salarisstrook 1",
    guarantorSalarySlipMonth2: "Garantsteller: Salarisstrook 2",
    guarantorSalarySlipMonth3: "Garantsteller: Salarisstrook 3",
    guarantorChamberOfCommerceFile: "Garantsteller: Uittreksel KvK",
    guarantorProfitLossFile: "Garantsteller: Winst-/verlies rekening",
    additionalFile1: "Optioneel: Overig document 1",
    additionalFile2: "Optioneel: Overig document 2",
    additionalFile3: "Optioneel: Overig document 3",
    additionalFile4: "Optioneel: Overig document 4",
    additionalFile5: "Optioneel: Overig document 5",
    additionalFile6: "Optioneel: Overig document 6",
    errorTitle: "Error",
    defaultErrorDesc:
      "Er is een fout opgetreden bij het uploaden van het bestand. Controleer uw verbinding of probeer een kleiner bestand.",
    ingeschrevenTot: "Ingeschreven tot: ",
    ingeschrevenOp: "Ingeschreven op: ",
    inschrijvingVerlopen:
      "Inschrijving verlopen: Neem contact op om je inschrijving te verlengen.",
    settingsAVGTekst:
      "Wil je dat we je account volledig uit ons systeem verwijderen? Neem dan even telefonisch contact met ons op.",
    uitlegDocumentenUploaden:
      "Let op: als je een document upload kan je deze alleen nog maar bekijken en niet verwijderen. Neem voor het verwijderen contact met ons op.",
    weetJeHetzeker: "Weet je het zeker?",
    geenVoorkeur: "Geen voorkeur",
    bredaEnOmgeving: "Breda en omgeving",
    geenAparteSlaapkamer: "Geen aparte slaapkamer",
    naarWoningaanbod: "Naar woningaanbod",
  },
};
