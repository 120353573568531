import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Container,
} from "@mui/material";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import ProgressIndicator from "../../../ProgressIndicator";
import Button from "@mui/material/Button";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from "moment";
import PhotoGallery from "./PhotoGallery";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import RedoIcon from "@mui/icons-material/Redo";

// Media queries
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "../../../MediaQueries";
import { appRoutes } from "../../../../constants/AppRoutes";
import { endpoints } from "../../../../constants/Endpoints";
import { useRef } from "react";
import Swal from "sweetalert2";
import { fileCategories } from "../../../../constants/FileTypes";

export function RentalPriceCalculationArchiveDetailView(props) {
  const isTabletOrMobileDevice = useMediaQuery(MediaQueries.MobileDevice);
  const [isMounted, setMounted] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const styles = {
    deleteButton: {
      marginLeft: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    defaultButton: {
      marginLeft: "20px",
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "red !important",
      },
    },
    saveButton: {
      backgroundColor: "#eb008b",
      marginLeft: "20px",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
    inactive: {
      color: "#ec2027",
      fontWeight: "bold",
    },
    active: {
      color: "green",
      fontWeight: "bold",
    },
    profilePicture: {
      borderRadius: "50%",
      width: "100%",
      display: "block",
      margin: "0 auto",
      minWidth: "100px",
      maxWidth: "140px",
      textAlign: "center",
    },
    alignRight: {
      textAlign: "right",
    },
    marginTop: {
      marginTop: "20px",
    },
  };

  const history = useHistory();
  const formRef = useRef();

  const textFieldVariant = "outlined";

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.lead.getRentalPriceCalculation + props.match.params.id
    );

    return await response.json();
  }, [props]);

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, getData]);

  const renewRentalPriceCalculation = async () => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.lead.renewRentalPriceCalculation + data.id
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });

      history.push(appRoutes.shb.lead.rentalPriceCalculationArchiveOverview);
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    var newData = await getData();
    setData(newData);

    setLoading(false);
  };

  const deleteRentalPriceCalculation = async () => {
    var response = await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.lead.deleteRentalPriceCalculation + data.id
    );

    if (response.ok) {
      history.push(appRoutes.shb.lead.rentalPriceCalculationArchiveOverview);
    }
  };

  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {isLoading ? (
          <div style={{ width: '100%', textAlign: 'center' }}>
            <ProgressIndicator />
          </div>
        ) : (
          <form id="lead-detail-view" ref={formRef}>
            <Container maxWidth={false} disableGutters={true}>
              {/* Buttons */}
              <Grid container>
                <Grid item xs={12} style={styles.alignRight}>
                  <Box pt={3}>
                    {/* Archive lead */}
                    <Button
                      onClick={(_) => {
                        Swal.fire({
                          title: "Vernieuwen",
                          text:
                            "Weet je zeker dat je de aanvraag wilt vernieuwen?",
                          icon: "info",
                          showCancelButton: true,
                          confirmButtonColor: "#eb008b",
                          confirmButtonText: "Vernieuw aanvraag",
                        }).then((result) => {
                          if (result.value) {
                            renewRentalPriceCalculation();
                          }
                        });
                      }}
                      variant="contained"
                      startIcon={<RedoIcon />}
                      style={styles.defaultButton}
                    >
                      {isTabletOrMobileDevice ? (
                        <span />
                      ) : (
                        <span>Vernieuwen</span>
                      )}
                    </Button>

                    <Button
                      onClick={(_) => {
                        Swal.fire({
                          title: "Definitief verwijderen",
                          text:
                            "Weet je zeker dat je de aanvraag definitief wilt verwijderen?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#ec2027",
                          confirmButtonText: "Verwijderen",
                        }).then((result) => {
                          if (result.value) {
                            setLoading(true);
                            deleteRentalPriceCalculation();
                          }
                        });
                      }}
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      style={styles.deleteButton}
                    >
                      {isTabletOrMobileDevice ? (
                        <span />
                      ) : (
                        <span>Definitief verwijderen</span>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {/* General info*/}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h4" gutterBottom={true}>
                                Aanvraag huurprijsberekening
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                color={"textSecondary"}
                              >
                                {"Datum aanvraag: " +
                                  moment(data.leadRegistrationDate).format(
                                    "DD-MM-YYYY"
                                  )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    required
                                    disabled
                                    label="Voorgestelde huurprijs"
                                    variant={textFieldVariant}
                                    name="proposedRentalPrice"
                                    type="number"
                                    defaultValue={
                                      data.proposedRentalPrice !== null
                                        ? data.proposedRentalPrice
                                        : 0
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <MobileDatePicker
                                    renderInput={(props) => <TextField fullWidth {...props} />}
                                    disabled
                                    inputVariant={textFieldVariant}
                                    label="Voorgesteld op"
                                    format="DD-MM-YYYY"
                                    onChange={(_) => { }} // On change is required, otherwise the datepicker will throw an error.
                                    value={
                                      data.dateRentalPriceProposedOn != null
                                        ? moment(data.dateRentalPriceProposedOn)
                                        : null
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    disabled
                                    name="shbNotes"
                                    multiline={true}
                                    minRows={5}
                                    variant={textFieldVariant}
                                    label="Eigen notities"
                                    defaultValue={
                                      data.shbNotes !== null ? data.shbNotes : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              {/* Rental Price Request info */}
              <Grid container spacing={2}>
                {/* Contact data lead */}
                <Grid item xs={12} md={4}>
                  <Paper>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        {/* Header */}
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">Aanvrager</Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel>Aanhef*</InputLabel>
                                    <Select
                                      disabled
                                      label="Aanhef*"
                                      type="string"
                                      name="leadApplicationUserSalutation"
                                      value={
                                        data.leadApplicationUserSalutation !==
                                          null
                                          ? data.leadApplicationUserSalutation
                                          : ""
                                      }
                                      fullWidth
                                    >
                                      <MenuItem value="meneer">Meneer</MenuItem>
                                      <MenuItem value="mevrouw">
                                        Mevrouw
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    required
                                    disabled
                                    variant={textFieldVariant}
                                    fullWidth
                                    label="Voornaam"
                                    name="leadApplicationUserFirstName"
                                    defaultValue={
                                      data.leadApplicationUserFirstName !== null
                                        ? data.leadApplicationUserFirstName
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    required
                                    disabled
                                    variant={textFieldVariant}
                                    fullWidth
                                    label="Achternaam"
                                    name="leadApplicationUserLastName"
                                    defaultValue={
                                      data.leadApplicationUserLastName !== null
                                        ? data.leadApplicationUserLastName
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel>
                                      Eigenaar van het pand*
                                    </InputLabel>
                                    <Select
                                      disabled
                                      label="Eigenaar van het pand*"
                                      type="string"
                                      name="isOwner"
                                      value={
                                        data.isOwner !== null
                                          ? data.isOwner
                                          : "Ja"
                                      }

                                      fullWidth
                                    >
                                      <MenuItem value="Ja">Ja</MenuItem>
                                      <MenuItem value="Nee">Nee</MenuItem>
                                      <MenuItem value="Binnenkort">
                                        Binnenkort
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    required
                                    disabled

                                    variant={textFieldVariant}
                                    fullWidth
                                    label="Telefoonnummer"
                                    name="leadApplicationUserPhoneNumber"
                                    defaultValue={
                                      data.leadApplicationUserPhoneNumber !==
                                        null
                                        ? data.leadApplicationUserPhoneNumber
                                        : ""
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                required
                                disabled
                                variant={textFieldVariant}
                                fullWidth
                                label="E-mail"
                                name="leadApplicationUserEmail"
                                defaultValue={
                                  data.leadApplicationUserEmail !== null
                                    ? data.leadApplicationUserEmail
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>

                {/* Property info */}
                <Grid item xs={12} md={8}>
                  <Paper>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        {/* Header */}
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Informatie huurwoning
                          </Typography>
                        </Grid>
                        {/* General info */}
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    required
                                    disabled

                                    label="Aantal m²"
                                    variant={textFieldVariant}
                                    type="number"
                                    name="surfaceInSquareMeters"
                                    defaultValue={
                                      data.surfaceInSquareMeters !== null
                                        ? data.surfaceInSquareMeters
                                        : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    required
                                    disabled

                                    label="Aantal slaapkamers"
                                    variant={textFieldVariant}
                                    type="number"
                                    name="numberOfBedrooms"
                                    defaultValue={
                                      data.numberOfBedrooms !== null
                                        ? data.numberOfBedrooms
                                        : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                  >
                                    <InputLabel>Oplevering*</InputLabel>
                                    <Select
                                      disabled
                                      label="Oplevering*"
                                      type="string"
                                      name="interior"
                                      value={
                                        data.interior !== null ? data.interior : ""
                                      }

                                      fullWidth
                                    >
                                      <MenuItem value="Gemeubileerd">
                                        Gemeubileerd
                                      </MenuItem>
                                      <MenuItem value="Ongemeubileerd">
                                        Ongemeubileerd
                                      </MenuItem>
                                      <MenuItem value="Beide mogelijk">
                                        Beide mogelijk
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    disabled
                                    name="extraInfo"
                                    multiline={true}
                                    minRows={5}
                                    variant={textFieldVariant}
                                    label="Extra informatie"
                                    defaultValue={
                                      data.extraInfo !== null ? data.extraInfo : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Address */}
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    required
                                    disabled
                                    variant={textFieldVariant}
                                    label="Straat"
                                    name="street"
                                    defaultValue={
                                      data.street !== null ? data.street : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                  <TextField
                                    required
                                    disabled
                                    label="Huisnummer"
                                    variant={textFieldVariant}
                                    type="number"
                                    name="number"
                                    defaultValue={
                                      data.number !== null ? data.number : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <TextField
                                    disabled

                                    label="Toevoeging"
                                    variant={textFieldVariant}
                                    name="addition"
                                    defaultValue={
                                      data.addition !== null
                                        ? data.addition
                                        : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                  <TextField
                                    required
                                    disabled

                                    variant={textFieldVariant}
                                    label="Postcode"
                                    name="zipCode"
                                    defaultValue={
                                      data.zipCode !== null ? data.zipCode : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <TextField
                                    required
                                    disabled
                                    variant={textFieldVariant}
                                    label="Plaats"
                                    name="city"
                                    defaultValue={
                                      data.city !== null ? data.city : ""
                                    }
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth={false} disableGutters={true}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Paper>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography gutterBottom={false} variant="h6">
                            Foto's
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <PhotoGallery
                            files={data.fileDocuments.filter(
                              (e) =>
                                e.fileCategory ===
                                fileCategories.rentalPriceCalculationImage
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper>
                    <Box p={3}>
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography gutterBottom={false} variant="h6">
                            Plattegrond
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <PhotoGallery
                            files={data.fileDocuments.filter(
                              (e) =>
                                e.fileCategory ===
                                fileCategories.rentalPriceCalculationFloorPlan
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </LocalizationProvider>
    </Fragment>
  );
}
