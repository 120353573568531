import React, { useState, useEffect, useCallback, useMemo } from "react";
import { styled } from "@mui/material/styles";
import MaterialTable, {
  MTableEditRow,
  MTableToolbar,
} from "@material-table/core";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import moment from "moment";
import {
  Grid,
  Button,
  Typography,
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../../services/ValidationService";
import { appRoutes } from "../../../../constants/AppRoutes";
import { endpoints } from "../../../../constants/Endpoints";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import { messageConstants } from "../../../../constants/MessageConstants";
import CodellicAction from "../../../CodellicAction";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";

// Icons
import AddBoxIcon from "@mui/icons-material/AddBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import RedoIcon from "@mui/icons-material/Redo";

const StyledLocalizationProvider = styled(LocalizationProvider)({});

export function RenterTable(props) {
  const refreshData = () => {
    setLoading(true);
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });
  };

  const styles = {
    paymentExpiresAt: {
      padding: "5px 10px",
      minWidth: "100px",
      textWrap: "nowrap",
    },
    expired: {
      backgroundColor: "#ec2027",
      color: "white",
      borderRadius: "50px",
    },
    exportToCsvButton: {
      marginLeft: "12px",
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    exportToCsvLink: {
      color: "white",
      textDecoration: "none",
    },
    newButton: {
      backgroundColor: "#eb008b",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    refreshButton: {
      marginLeft: "12px",
      backgroundColor: "#f68121",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      marginLeft: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
  };

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.base,
      null
    );
    if (response.ok) {
      return await response.json();
    }

    // Logout if this call fails.
    window.location.href = "/Identity/Account/Logout";
  }, []);

  const sendData = async (newRenter) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.create,
      newRenter
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    refreshData();
  };

  const sendAccountActivationEmail = async (renterId, email) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.sendAccountActivationEmail +
        "?renterId=" +
        renterId +
        "&email=" +
        email
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }
    setLoading(false);
  };

  const deleteData = async (id) => {
    await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.renter.base + id,
      null
    );
  };

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const columns = useMemo(
    () => [
      {
        title: "Voornaam*",
        field: "applicationUserFirstName",
      },
      {
        title: "Achternaam*",
        field: "applicationUserLastName",
      },
      {
        title: "E-mail*",
        field: "applicationUserEmail",
      },
      {
        title: "Telefoonnummer",
        field: "applicationUserPhoneNumber",
      },
      {
        title: "Taal",
        field: "applicationUserLocale",
        lookup: {
          nl_NL: "NL",
          en_US: "EN",
        },
        render: (rowData) =>
          rowData.applicationUserLocale === "nl_NL" ? "NL" : "EN",
      },
      {
        title: "Documenten",
        field: "fileDocumentsCount",
        editable: "never",
        render: (rowData) =>
          rowData?.fileDocumentsCount ? rowData.fileDocumentsCount : "0",
      },
      {
        size: 200,
        title: "Betaald tot*",
        field: "paymentExpiresAt",
        type: "date",
        render: (rowData) => (
          <span
            style={
              new Date(rowData.paymentExpiresAt) < new Date()
                ? { ...styles.paymentExpiresAt, ...styles.expired }
                : styles.paymentExpiresAt
            }
          >
            {moment(rowData.paymentExpiresAt).format("DD-MM-YYYY").toString()}
          </span>
        ),
      },
    ],
    []
  );

  const csvHeaders = [
    { label: "Achternaam", key: "applicationUserLastName" },
    { label: "Voornaam", key: "applicationUserFirstName" },
    { label: "Bedrijfsnaam", key: "applicationUserCompanyName" },
    { label: "Telefoonnummer", key: "applicationUserPhoneNumber" },
    { label: "Email", key: "applicationUserEmail" },
    { label: "Stad", key: "city" },
    { label: "Straat", key: "street" },
    { label: "Huisnummer", key: "houseNumber" },
    { label: "Postcode", key: "zipCode" },
    { label: "Toevoeging", key: "addition" },
    { label: "Netto inkomen per maand", key: "netIncomePerMonth" },
    { label: "Maximum prijs", key: "preferredMaxPrice" },
    { label: "Voorkeur stad", key: "preferredCity" },
    { label: "Voorkeur interieur", key: "preferredInterior" },
    { label: "Voorkeur wijk", key: "preferredDistrict" },
    {
      label: "Minimum aantal slaapkamers",
      key: "preferredMinNumberOfBedrooms",
    },
    { label: "Wil huren vanaf", key: "preferredStartOfRentingDate" },
    { label: "Overige wensen", key: "wishes" },
    { label: "Taalcode", key: "applicationUserLocale" },
  ];

  const [isMounted, setMounted] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, getData]);

  const [showDateModal, setShowDateModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const startSubscriptionRenewal = (id) => {
    setCurrentId(id);
    setSelectedDate(null);
    setShowDateModal(true);
  };

  const renewSubscription = async (value) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.renewSubscription,
      { renterId: currentId, newDate: selectedDate.toISOString(true) }
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    var newData = await getData();
    setData(newData);

    setLoading(false);
  };

  const actions = [
    {
      icon: "mail",
      tooltip: "Stuur activatie mail",
      onClick: (event, rowData) =>
        Swal.fire({
          title: "",
          text: "Activatiemail versturen naar?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#eb008b",
          confirmButtonText: "Versturen",
          input: "text",
          inputPlaceholder: "E-mailadres",
          inputValue: rowData.applicationUserEmail,
          inputValidator: (email) => {
            if (!email) {
              return "E-mailadres is verplicht";
            }
            return "";
          },
        }).then((result) => {
          if (result.isConfirmed) {
            sendAccountActivationEmail(rowData.id, result.value);
          }
        }),
    },
    {
      icon: () => <RedoIcon />,
      tooltip: "Verleng inschrijving",
      onClick: (_, rowData) => startSubscriptionRenewal(rowData.id),
    },
  ];

  const components = {
    EditRow: (props) => {
      const propsCopy = { ...props };
      return <MTableEditRow {...propsCopy} />;
    },
    Action: (props) => <CodellicAction {...props} />,
    Toolbar: (props) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Grid container>
                {/* General data */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color="primary">
                    Inschrijvingen
                  </Typography>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        style={styles.newButton}
                        startIcon={<AddBoxIcon />}
                        onClick={(_) =>
                          document.getElementById("CA-Add").click()
                        }
                        edge="end"
                      >
                        <span>Nieuw</span>
                      </Button>
                    </Grid>
                    <Grid item>
                      <CSVLink
                        data={data}
                        style={styles.exportToCsvLink}
                        headers={csvHeaders}
                        filename="huurders.csv"
                      >
                        <Button
                          variant="contained"
                          startIcon={<SystemUpdateAltIcon />}
                          style={styles.exportToCsvButton}
                        >
                          Download CSV
                        </Button>
                      </CSVLink>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={styles.refreshButton}
                        startIcon={<RefreshIcon />}
                        onClick={() => refreshData()}
                        edge="end"
                      >
                        <span>Vernieuw</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Toolbar */}
          <Grid item xs={12}>
            <Box paddingBottom={3}>
              <MTableToolbar {...props} gutters={false} />
            </Box>
          </Grid>
        </Grid>
      );
    },
  };

  const editable = {
    onRowDelete: (oldData) =>
      new Promise((resolve) => {
        deleteData(oldData.id).then((_) => {
          setLoading(true);
          getData().then((v) => {
            if (isMounted) {
              setData(v);
              setLoading(false);
            }
          });
        });
        resolve();
      }),
    onRowAdd: (newRowData) => {
      return new Promise((resolve, reject) => {
        if (
          newRowData.applicationUserEmail == null ||
          newRowData.applicationUserFirstName == null ||
          newRowData.applicationUserLastName == null ||
          newRowData.paymentExpiresAt == null
        ) {
          // Missing data
          Swal.fire({
            icon: messageConstants.icons.error,
            title: "Niet alle verplichte velden zijn ingevuld",
            text: "Vul alle verplichte velden in (Voornaam, Achternaam, E-mail en Betaald tot) om  de inschrijving te voltooien.",
          });
          reject();
        } else if (!validateEmail(newRowData.applicationUserEmail)) {
          // Invalid e-mail
          Swal.fire({
            icon: messageConstants.icons.error,
            title: "Ongeldig e-mailadres",
            text: "Vul een geldig e-mailadres in om de inschrijving te voltooien.",
          });
          reject();
        } else {
          // Valid input
          sendData(newRowData);
          resolve();
        }
      });
    },
  };

  const localization = {
    header: {
      actions: "Acties",
    },
    body: {
      editRow: {
        deleteText: "Weet je zeker dat je deze persoon wilt uitschrijven?",
      },
    },
    toolbar: {
      searchPlaceholder: "Zoeken",
    },
  };

  const onRowClick = (rowData) => {
    history.push(appRoutes.shb.renter.detail + rowData.id);
  };

  const options = {
    headerStyle: {
      padding: "16px",
    },
    cellStyle: {
      padding: "16px",
    },
    searchFieldAlignment: "left",
    searchFieldStyle: {
      marginLeft: "-16px",
    },
  };

  return (
    <StyledLocalizationProvider dateAdapter={AdapterMoment}>
      <MaterialTable
        title=""
        actions={actions}
        columns={columns}
        components={components}
        data={data}
        editable={editable}
        isLoading={isLoading}
        localization={localization}
        options={options}
        onRowClick={(_, rowData) => onRowClick(rowData)}
      />
      <MobileDatePicker
        renderInput={() => null}
        open={showDateModal}
        onOpen={() => setShowDateModal(true)}
        onClose={() => setShowDateModal(false)}
        onAccept={(value) => renewSubscription(value)}
        onChange={setSelectedDate}
        value={selectedDate}
        componentsProps={{
          actionBar: {
            actions: ["accept", "cancel"],
          },
        }}
      />
    </StyledLocalizationProvider>
  );
}
