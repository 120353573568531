import React, { useState, useEffect, useCallback, useMemo } from "react";
import MaterialTable, {
  MTableEditRow,
  MTableToolbar,
} from "@material-table/core";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import * as moment from "moment";
import { Grid, Button, Typography, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../../../services/ValidationService";
import { appRoutes } from "../../../../constants/AppRoutes";
import { endpoints } from "../../../../constants/Endpoints";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import CodellicAction from "../../../CodellicAction";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";

// Icons
import AddBoxIcon from "@mui/icons-material/AddBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import RedoIcon from "@mui/icons-material/Redo";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

export function LeadArchiveTable(props) {
  const refreshData = () => {
    setLoading(true);
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });
  };

  const styles = {
    exportToCsvButton: {
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    exportToCsvLink: {
      color: "white",
      textDecoration: "none",
    },
    newButton: {
      backgroundColor: "#eb008b",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    refreshButton: {
      backgroundColor: "#f68121",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      marginLeft: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
  };

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.lead.getArchivedRentalPriceCalculations,
      null
    );
    return await response.json();
  }, []);

  const deleteData = async (id) => {
    await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.lead.deleteRentalPriceCalculation + id
    );
  };

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: "Straat",
        field: "street",
      },
      {
        title: "Nummer",
        field: "number",
      },
      {
        title: "Aanhef",
        field: "leadApplicationUserSalutation",
      },
      {
        title: "Voornaam",
        field: "leadApplicationUserFirstName",
      },
      {
        title: "Achternaam",
        field: "leadApplicationUserLastName",
      },
      {
        title: "E-mail",
        field: "leadApplicationUserEmail",
      },
      {
        title: "Telefoonnummer",
        field: "leadApplicationUserPhoneNumber",
      },
      {
        title: "Datum aanvraag",
        field: "leadRegistrationDate",
        type: "date",
        render: (rowData) => (
          <span>
            {moment(rowData.leadRegistrationDate)
              .format("DD-MM-YYYY")
              .toString()}
          </span>
        ),
      },
    ],
    []
  );

  const csvHeaders = [
    { label: "Datum aanvraag", key: "leadRegistrationDate" },
    { label: "Aanvraag bevestigd", key: "leadIsConfirmed" },
    { label: "Aanhef", key: "leadApplicationUserSalutation" },
    { label: "Achternaam", key: "leadApplicationUserLastName" },
    { label: "Voornaam", key: "leadApplicationUserFirstName" },
    { label: "Telefoonnummer", key: "leadApplicationUserPhoneNumber" },
    { label: "Email", key: "leadApplicationUserEmail" },
    { label: "Is eigenaar", key: "isOwner" },
    { label: "Aantal slaapkamers", key: "numberOfBedrooms" },
    { label: "Aantal m2", key: "surfaceInSquareMeters" },
    { label: "Oplevering", key: "interior" },
    { label: "Straat", key: "street" },
    { label: "Huisnummer", key: "houseNumber" },
    { label: "Toevoeging", key: "addition" },
    { label: "Postcode", key: "zipCode" },
    { label: "Stad", key: "city" },
    { label: "Extra info", key: "extraInfo" },
    { label: "Voorgestelde huurprijs", key: "proposedRentalPrice" },
    { label: "Voorgesteld op", key: "dateRentalPriceProposedOn" },
    { label: "Eigen notities", key: "SHBNotes" },
  ];

  const [isMounted, setMounted] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, getData]);

  const renewRentalPriceCalculation = async (id) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.lead.renewRentalPriceCalculation + id
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    var newData = await getData();
    setData(newData);

    setLoading(false);
  };

  const [showDateModal, setShowDateModal] = useState(false);

  const actions = [
    {
      icon: () => <RedoIcon />,
      tooltip: "Vernieuw huurprijsaanvraag",
      onClick: (event, rowData) =>
        Swal.fire({
          title: "Huurprijsaanvraag vernieuwen",
          text: "Wil je de huurprijsaanvrag vernieuwen?",
          icon: "info",
          showCancelButton: true,
          confirmButtonColor: "#eb008b",
          confirmButtonText: "Vernieuw aanvraag",
        }).then((result) => {
          if (result.value) {
            renewRentalPriceCalculation(rowData.id);
          }
        }),
    },
  ];

  const components = {
    EditRow: (props) => {
      const propsCopy = { ...props };
      return <MTableEditRow {...propsCopy} />;
    },
    Action: (props) => <CodellicAction {...props} />,
    Toolbar: (props) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Grid container>
                {/* General data */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color="primary">
                    Huurprijsaanvragen archief
                  </Typography>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <CSVLink
                        data={data}
                        style={styles.exportToCsvLink}
                        headers={csvHeaders}
                        filename="archief_huurprijscalculatie.csv"
                      >
                        <Button
                          variant="contained"
                          startIcon={<SystemUpdateAltIcon />}
                          style={styles.exportToCsvButton}
                        >
                          Download CSV
                        </Button>
                      </CSVLink>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={styles.refreshButton}
                        startIcon={<RefreshIcon />}
                        onClick={refreshData}
                        edge="end"
                      >
                        <span>Vernieuw</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Toolbar */}
          <Grid item xs={12}>
            <Box paddingBottom={3}>
              <MTableToolbar {...props} gutters={false} />
            </Box>
          </Grid>
        </Grid>
      );
    },
  };

  const editable = {
    onRowDelete: (oldData) =>
      new Promise((resolve) => {
        deleteData(oldData.id).then((_) => {
          setLoading(true);
          getData().then((v) => {
            if (isMounted) {
              setData(v);
              setLoading(false);
            }
          });
        });
        resolve();
      }),
  };

  const localization = {
    header: {
      actions: "Acties",
    },
    body: {
      editRow: {
        deleteText: "Weet je zeker dat je deze persoon wilt uitschrijven?",
      },
    },
    toolbar: {
      searchPlaceholder: "Zoeken",
    },
  };

  const onRowClick = (rowData) => {
    history.push(
      appRoutes.shb.lead.rentalPriceCalculationArchiveDetail + rowData.id
    );
  };

  const options = {
    headerStyle: {
      padding: "16px",
    },
    cellStyle: {
      padding: "16px",
    },
    searchFieldAlignment: "left",
    searchFieldStyle: {
      marginLeft: "-16px",
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MaterialTable
        title=""
        actions={actions}
        columns={columns}
        components={components}
        data={data}
        editable={editable}
        isLoading={isLoading}
        localization={localization}
        options={options}
        onRowClick={(_, rowData) => onRowClick(rowData)}
      />
    </LocalizationProvider>
  );
}
