import React, { useState, useEffect, useCallback, useMemo } from "react";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import { useHistory } from "react-router-dom";
import { appRoutes } from "../../../../constants/AppRoutes";
import { endpoints } from "../../../../constants/Endpoints";
import { CSVLink } from "react-csv";
import {
  Box,
  Grid,
  Button,
  Typography,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  IconButton,
  Paper,
} from "@mui/material";
import CodellicAction from "../../../CodellicAction";
import Swal from "sweetalert2";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";

// Icons
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import RefreshIcon from "@mui/icons-material/Refresh";
import RedoIcon from "@mui/icons-material/Redo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import MaterialTable, { MTableToolbar } from "@material-table/core";

export function RenterArchiveTable(props) {
  const styles = {
    exportToCsvButton: {
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    exportToCsvLink: {
      color: "white",
      textDecoration: "none",
    },
    newButton: {
      backgroundColor: "#eb008b",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    refreshButton: {
      backgroundColor: "#f68121",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      marginLeft: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
  };

  const refreshData = () => {
    setLoading(true);
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });
  };

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.getArchive,
      null
    );
    return await response.json();
  }, []);

  const deleteData = async (id) => {
    await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.renter.delete + id,
      null
    );
  };

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const columns = useMemo(
    () => [
      {
        title: "Voornaam",
        field: "applicationUserFirstName",
      },
      {
        title: "Achternaam",
        field: "applicationUserLastName",
      },
      {
        title: "E-mail",
        field: "applicationUserEmail",
      },
      {
        title: "Telefoonnummer",
        field: "applicationUserPhoneNumber",
      },
    ],
    []
  );

  const csvHeaders = [
    { label: "Achternaam", key: "applicationUserLastName" },
    { label: "Voornaam", key: "applicationUserFirstName" },
    { label: "Bedrijfsnaam", key: "applicationUserCompanyName" },
    { label: "Telefoonnummer", key: "applicationUserPhoneNumber" },
    { label: "Email", key: "applicationUserEmail" },
    { label: "Stad", key: "city" },
    { label: "Straat", key: "street" },
    { label: "Huisnummer", key: "houseNumber" },
    { label: "Postcode", key: "zipCode" },
    { label: "Toevoeging", key: "addition" },
    { label: "Netto inkomen per maand", key: "netIncomePerMonth" },
    { label: "Maximum prijs", key: "preferredMaxPrice" },
    { label: "Voorkeur stad", key: "preferredCity" },
    { label: "Voorkeur interieur", key: "preferredInterior" },
    { label: "Voorkeur wijk", key: "preferredDistrict" },
    {
      label: "Minimum aantal slaapkamers",
      key: "preferredMinNumberOfBedrooms",
    },
    { label: "Wil huren vanaf", key: "preferredStartOfRentingDate" },
    { label: "Overige wensen", key: "wishes" },
    { label: "Taalcode", key: "applicationUserLocale" },
  ];

  const [isMounted, setMounted] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, getData]);

  const actions = [
    {
      icon: "refresh",
      tooltip: "Refresh Data",
      isFreeAction: true,
      onClick: refreshData,
    },
    {
      icon: () => <RedoIcon />,
      tooltip: "Verleng inschrijving",
      onClick: (event, rowData) => startSubscriptionRenewal(rowData.id),
    },
  ];

  const components = {
    Action: (props) => <CodellicAction {...props} />,
    Toolbar: (props) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Grid container>
                {/* General data */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" color="primary">
                    Inschrijvingen archief
                  </Typography>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} md={6}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <CSVLink
                        data={data}
                        style={styles.exportToCsvLink}
                        headers={csvHeaders}
                        filename="archief_huurders.csv"
                      >
                        <Button
                          variant="contained"
                          startIcon={<SystemUpdateAltIcon />}
                          style={styles.exportToCsvButton}
                        >
                          Download CSV
                        </Button>
                      </CSVLink>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        style={styles.refreshButton}
                        startIcon={<RefreshIcon />}
                        onClick={refreshData}
                        edge="end"
                      >
                        <span>Vernieuw</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Toolbar */}
          <Grid item xs={12}>
            <Box paddingBottom={3}>
              <MTableToolbar {...props} gutters={false} />
            </Box>
          </Grid>
        </Grid>
      );
    },
  };

  const editable = {
    onRowDelete: (oldData) =>
      new Promise((resolve) => {
        deleteData(oldData.id).then((_) => {
          setLoading(true);
          getData().then((v) => {
            if (isMounted) {
              setData(v);
              setLoading(false);
            }
          });
        });
        resolve();
      }),
  };

  const localization = {
    header: {
      actions: "Acties",
    },
    body: {
      editRow: {
        deleteText:
          "Weet je zeker dat je alle gegevens van deze persoon definitief wilt verwijderen?",
      },
    },
    toolbar: {
      searchPlaceholder: "Zoeken",
    },
  };

  const onRowClick = (rowData) => {
    history.push(appRoutes.shb.archive.detail + rowData.id);
  };

  const options = {
    headerStyle: {
      padding: "16px 2px",
    },
    cellStyle: {
      padding: "16px 2px",
    },
    searchFieldAlignment: "left",
    searchFieldStyle: {
      marginLeft: "-16px",
    },
  };

  const [showDateModal, setShowDateModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  const startSubscriptionRenewal = (id) => {
    setCurrentId(id);
    setSelectedDate(null);
    setShowDateModal(true);
  };

  const renewSubscription = async (date) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.renewSubscription,
      { renterId: currentId, newDate: date.toISOString(true) }
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    var newData = await getData();
    setData(newData);

    setLoading(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MaterialTable
        title=""
        actions={actions}
        columns={columns}
        components={components}
        data={data}
        editable={editable}
        isLoading={isLoading}
        localization={localization}
        options={options}
        onRowClick={(_, rowData) => onRowClick(rowData)}
      />
      <MobileDatePicker
        renderInput={() => null}
        open={showDateModal}
        onOpen={() => setShowDateModal(true)}
        onClose={() => setShowDateModal(false)}
        onAccept={renewSubscription}
        onChange={setSelectedDate}
        value={selectedDate}
        componentsProps={{
          actionBar: {
            actions: ["accept", "cancel"],
          },
        }}
      />
    </LocalizationProvider>
  );
}
