import React from 'react';
import { LeadArchiveTable } from './overview/LeadArchiveTable';

export function LeadArchiveOverview() {
    return (
        <div id='page-lead-overview'>
            <div className="content-wrapper">
                <section className='content-body'>
                    <LeadArchiveTable />
                </section>
            </div>
        </div>
    );
}