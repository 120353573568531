import { Icon, IconButton, Tooltip } from '@mui/material';
import React from 'react';

export default class CodellicAction extends React.Component {

    render() {
        let action = this.props.action;
        if (typeof action === 'function') {
            action = action(this.props.data);
            if (!action) {
                return null;
            }
        }

        const handleOnClick = event => {
            if (action.onClick) {
                action.onClick(event, this.props.data);
                event.stopPropagation();
            }
        };

        const button = (
            <span>
                <IconButton
                    id={this.props.action.position === 'toolbar' ? `CA-${this.props.action.tooltip}` : ""}
                    color="inherit"
                    style={{
                        display: this.props.action.position === 'toolbar' ? 'none' : 'inline-flex'
                    }}
                    disabled={action.disabled}
                    disableRipple
                    onClick={(event) => handleOnClick(event)}
                >
                    {typeof action.icon === "string" ? (
                        <Icon {...action.iconProps} fontSize="small">{action.icon}</Icon>
                    ) : (
                            <action.icon
                                {...action.iconProps}
                                disabled={action.disabled}
                            />
                        )
                    }
                </IconButton>
            </span>
        );

        if (action.tooltip) {
            return <Tooltip title={action.tooltip}>{button}</Tooltip>;
        } else {
            return button;
        }
    }
}