import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "./components/Layout";
import { RenterOverview } from "./components/shb/renter/RenterOverview";
import { RenterDetailView } from "./components/shb/renter/RenterDetailView";
import { RenterArchiveOverview } from "./components/shb/archive/RenterArchiveOverview";
import { RenterArchiveDetailView } from "./components/shb/archive/RenterArchiveDetailView";
import { LeadOverview } from "./components/shb/lead/LeadOverview";
import { LeadArchiveOverview } from "./components/shb/lead/LeadArchiveOverview";
import { RentalPriceCalculationDetailView } from "./components/shb/lead/rental-price-calculation/RentalPriceCalculationDetailView";
import { RentalPriceCalculationArchiveDetailView } from "./components/shb/lead/rental-price-calculation/RentalPriceCalculationArchiveDetailView";
import { PropertyOverview } from "./components/shb/property/PropertyOverview";
import { ProfileDetailView } from "./components/renter/ProfileDetailView";
import { DocumentsView } from "./components/renter/DocumentsView";
import { SettingsView } from "./components/renter/SettingsView";
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import ProfileService, { UserRoles } from "./services/ProfileService";
import { appRoutes } from "./constants/AppRoutes";

export default function App() {
    const outerTheme = createTheme({
        palette: {
            primary: { main: "#eb108b", contrastText: "#ffffff" },
            secondary: { main: "#eb108b", contrastText: "#ffffff" },
            error: { main: "#ec2027", contrastText: "#ffffff" },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                    body {
                        background-image: url("/media/img/shb-patroon-grijs.png");
                    }
                `,
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        marginTop: "1rem",
                    }
                }
            },
        },
    });

    const init = () => {
        if (ProfileService.getUserDetails() === null) {
            window.location.href = "/Identity/Account/Login";
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <ThemeProvider theme={outerTheme}>
            <Layout outerTheme={outerTheme}>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => {
                            if (ProfileService.isInRole(UserRoles.codellic)) {
                                return <Redirect to={appRoutes.codellic.home} />;
                            } else if (ProfileService.isInRole(UserRoles.shb)) {
                                return <Redirect to={appRoutes.shb.renter.overview} />;
                            } else if (ProfileService.isInRole(UserRoles.renter)) {
                                return <Redirect to={appRoutes.renter.profile} />;
                            } else if (ProfileService.isInRole(UserRoles.landlord)) {
                                return <Redirect to={appRoutes.landlord.home} />;
                            } else {
                                return <></>;
                            }
                        }}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.renter.overview}
                        component={RenterOverview}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.archive.overview}
                        component={RenterArchiveOverview}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.archive._detail}
                        component={RenterArchiveDetailView}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.lead.overview}
                        component={LeadOverview}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.lead.rentalPriceCalculationArchiveOverview}
                        component={LeadArchiveOverview}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.lead._rentalPriceCalculationDetail}
                        component={RentalPriceCalculationDetailView}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.lead._rentalPriceCalculationArchiveDetail}
                        component={RentalPriceCalculationArchiveDetailView}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.property.overview}
                        component={PropertyOverview}
                    />
                    <Route
                        exact
                        path={appRoutes.shb.renter._detail}
                        component={RenterDetailView}
                    />
                    <Route
                        exact
                        path={appRoutes.renter.profile}
                        component={ProfileDetailView}
                    />
                    <Route
                        exact
                        path={appRoutes.renter.documents}
                        component={DocumentsView}
                    />
                    <Route
                        exact
                        path={appRoutes.renter.settings}
                        component={SettingsView}
                    />
                </Switch>
            </Layout>
        </ThemeProvider>
    );
}
