import React, { useState, useEffect, Fragment } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import HttpClient, { Methods } from "../../services/HttpClient";
import ProgressIndicator from "../ProgressIndicator";
import TranslationService from "../../services/TranslationService";

// Media queries
import { endpoints } from "../../constants/Endpoints";
import UploadBlock from "./upload/UploadBlock";
import { fileCategories } from "../../constants/FileTypes";

export function DocumentsView(props) {
  const [isMounted, setMounted] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState([]);

  const handleTabChange = (event, index) => {
    setTabIndex(index);
  };

  useEffect(() => {
    getData().then((_) => {
      setLoading(false);
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, props]);

  const getData = async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.getDocuments,
      null
    );

    if (response.ok) {
      let data = await response.json();
      setData(data);
    }

    return response.ok;
  };

  return (
    <Fragment>
      {isLoading ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <ProgressIndicator />
        </div>) : (
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8} xl={6}>
              <Paper>
                <Box p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {TranslationService.l.mijnDocumenten}
                      </Typography>
                      <Typography variant="body2">
                        {TranslationService.l.uitlegDocumentenUploaden}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleTabChange}
                        aria-label="Tabs"
                        variant="scrollable"
                        scrollButtons={true}
                      >
                        <Tab
                          label={
                            <div>
                              {TranslationService.l.legitimatie}
                              <span>
                                {" "}
                                (
                                {
                                  data.filter(
                                    (x) =>
                                      x.fileCategory ===
                                      fileCategories.identification
                                  ).length
                                }
                                /4)
                              </span>
                            </div>
                          }
                        />
                        <Tab
                          label={
                            <div>
                              {TranslationService.l.inkomensgegevens}
                              <span>
                                {" "}
                                (
                                {
                                  data.filter(
                                    (x) =>
                                      x.fileCategory ===
                                      fileCategories.financialDetails
                                  ).length
                                }
                                /7)
                              </span>
                            </div>
                          }
                        />
                        <Tab
                          label={
                            <div>
                              {TranslationService.l.garantSteller}
                              <span>
                                {" "}
                                (
                                {
                                  data.filter(
                                    (x) =>
                                      x.fileCategory ===
                                      fileCategories.guarantor
                                  ).length
                                }
                                /9)
                              </span>
                            </div>
                          }
                        />
                        <Tab
                          label={
                            <div>
                              {TranslationService.l.additioneleDocumenten}
                              <span>
                                {" "}
                                (
                                {
                                  data.filter(
                                    (x) =>
                                      x.fileCategory ===
                                      fileCategories.additional
                                  ).length
                                }
                                /6)
                              </span>
                            </div>
                          }
                        />
                      </Tabs>
                      {tabIndex === 0 ? (
                        <Box paddingTop={4}>
                          <Grid container spacing={2}>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={0}
                                fileCategory={fileCategories.identification}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 0).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={1}
                                fileCategory={fileCategories.identification}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 1).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={24}
                                fileCategory={fileCategories.identification}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 24).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={25}
                                fileCategory={fileCategories.identification}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 25).length >
                                  0
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                      {tabIndex === 1 ? (
                        <Box paddingTop={4}>
                          <Grid container spacing={2}>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={2}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 2).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={3}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 3).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={4}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 4).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={5}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 5).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={21}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 21).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={22}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 22).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={23}
                                fileCategory={fileCategories.financialDetails}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 23).length >
                                  0
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                      {tabIndex === 2 ? (
                        <Box paddingTop={4}>
                          <Grid container spacing={2} alignContent="center">
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={6}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 6).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={7}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 7).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={8}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 8).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={9}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 9).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={10}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 10).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={11}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 11).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={12}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 12).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={13}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 13).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              <UploadBlock
                                fileType={14}
                                fileCategory={fileCategories.guarantor}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 14).length >
                                  0
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                      {tabIndex === 3 ? (
                        <Box paddingTop={4}>
                          <Grid container spacing={2}>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={15}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 15).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={16}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 16).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={17}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 17).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={18}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 18).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={19}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 19).length >
                                  0
                                }
                              />
                            </Grid>
                            <Grid item md={3} sm={4} xs={12}>
                              {" "}
                              <UploadBlock
                                fileType={20}
                                fileCategory={fileCategories.additional}
                                refreshCallback={getData}
                                uploaded={
                                  data.filter((x) => x.fileType === 20).length >
                                  0
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </Fragment>
  );
}
