export const endpoints = {
  properties: {
    base: "api/Property/",
  },
  renter: {
    base: "api/Renter/",
    getArchive: "api/Renter/GetArchive/",
    delete: "api/Renter/DeleteRenter/",
    create: "api/Renter/CreateRenter/",
    getProfile: "api/Renter/GetProfile/",
    sendAccountActivationEmail: "api/Renter/SendAccountActivationEmail/",
    sendFilesToEmail: "api/Renter/SendFilesToEmail/",
    renewSubscription: "api/Renter/RenewSubscription/",
    updateProfile: "api/Renter/UpdateProfile/",
    updateSettings: "api/Renter/UpdateSettings/",
    getDocuments: "api/Renter/GetDocuments/",
    getActiveStatus: "api/renter/GetActiveStatus/",
    uploadDocument: "api/Renter/UploadRenterDocument/",
  },
  lead: {
    base: "api/Lead/",
    getRentalPriceCalculations: "api/Lead/GetRentalPriceCalculations/",
    getRentalPriceCalculation: "api/Lead/GetRentalPriceCalculation/",
    getArchivedRentalPriceCalculations:
    "api/Lead/GetArchivedRentalPriceCalculations/",
    updateRentalPriceCalculation: "api/Lead/UpdateRentalPriceCalculation/",
    sendRentalPriceCalculationActivationMail:
    "api/Lead/SendRentalPriceCalculationActivationMail/",
    removeRentalPriceCalculation: "api/Lead/RemoveRentalPriceCalculation/",
    deleteRentalPriceCalculation: "api/Lead/DeleteRentalPriceCalculation/",
    renewRentalPriceCalculation: "api/Lead/RenewRentalPriceCalculation/",
    getImage: "api/Lead/GetImage/"
  },
  document: {
    bindRenterDocumentToUser: "api/Document/BindRenterDocumentToUser/",
    downloadRenterDocument: "api/Document/DownloadRenterDocument/",
    shbDownloadRenterDocument: "api/Document/SHBDownloadRenterDocument/",
    deleteRenterDocument: "api/Document/DeleteRenterDocument/",
  },
  auth: {
    refreshToken: "api/Auth/RefreshToken/",
  }
};
