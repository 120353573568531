import React from "react";
import { RenterArchiveTable } from "./overview/RenterArchiveTable";

export function RenterArchiveOverview() {
  return (
    <div id="page-renter-overview">
      <div className="content-wrapper">
        <section className="content-body">
          <RenterArchiveTable />
        </section>
      </div>
    </div>
  );
}
