export const appRoutes = {
  shb: {
    renter: {
      overview: "/shb/renter/overview/",
      detail: "/shb/renter/",
      _detail: "/shb/renter/:id",
    },
    lead: {
      rentalPriceCalculationArchiveOverview: "/shb/lead/archive/overview/",
      overview: "/shb/lead/overview/",
      rentalPriceCalculationDetail: "/shb/lead/rental-price-calculation/",
      _rentalPriceCalculationDetail: "/shb/lead/rental-price-calculation/:id",
      rentalPriceCalculationArchiveDetail:
        "/shb/lead/archive/rental-price-calculation/",
      _rentalPriceCalculationArchiveDetail:
        "/shb/lead/archive/rental-price-calculation/:id",
    },
    archive: {
      overview: "/shb/renter/archive/overview/",
      detail: "/shb/renter/archive/",
      _detail: "/shb/renter/archive/:id",
    },
    property: {
      overview: "/shb/property/overview/",
    },
  },
  renter: {
    profile: "/renter/profile/",
    documents: "/renter/documents/",
    settings: "/renter/settings/",
  },
  codellic: {
    home: "/codellic/home/",
  },
  landlord: {
    home: "/landlord/home/",
  },
};
