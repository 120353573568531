import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import HttpClient, { Methods } from "../services/HttpClient";
import { endpoints } from "../constants/Endpoints";
import { makeStyles, useTheme } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import ProfileService, { UserRoles } from "../services/ProfileService";
import { appRoutes } from "../constants/AppRoutes";
import TranslationService from "../services/TranslationService";

// Media queries
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "./MediaQueries";
import { isTabletOrMobileDevice } from "react-responsive";

// Icons
import HomeIcon from "@mui/icons-material/Home";
import StorageIcon from "@mui/icons-material/Storage";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import DescriptionIcon from "@mui/icons-material/Description";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { List, ListItemButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import BusinessIcon from "@mui/icons-material/Business";
import SHBLeadBadge from "./SHBLeadBadge";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

/*
 * The following properties are used in this documentation website for optimal usability of the component:
 * - iOS is hosted on high-end devices. The backdrop transition can be enabled without dropping frames. The performance will be good enough.
 * - iOS has a "swipe to go back" feature that interferes with the discovery feature, so discovery has to be disabled.
 */

//const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
//<SwipeableDrawer disableBackdropTransition={!iOS} disableDiscovery={iOS} />

export default function CustomDrawer(props) {
  const drawerWidth = 240;

  const [isActive, setActive] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 1000,
      backgroundColor: !isActive ? "#ec2027 !important" : null,
    },
    menuItem: {
      color: "unset",

      "&:hover": {
        color: "var(--mdc-theme-primary)",
      },
    },
    menuIcon: {
      minWidth: "40px",
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    goToPropertiesButton: {
      marginLeft: "4px",
      backgroundColor: "#ffffff",
      color: "#eb008b",
      "&:hover": {
        color: "#ffffff",
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      padding: "12px !important",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 1040,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: !isTabletOrMobileDevice ? -drawerWidth : 0,
      overflow: "auto",
    },
    inactiveText: {
      color: "white",
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,

      "&.shift": {
        marginLeft: drawerWidth - 40
      }
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    overlay: {
      pointerEvents: "none",
      display: "none",
    },
    overlayActive: {
      pointerEvents: "all",
      display: "block",
      position: "fixed",
      zIndex: 1025,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "black",
      opacity: ".5",
    },
    toolbarButtons: {
      marginLeft: "auto",
    },
  }));

  const isTabletOrMobileDeviceQuery = useMediaQuery(
    MediaQueries.TabletOrMobileDevice
  );
  const isDrawerExpanded = isTabletOrMobileDeviceQuery ? false : true;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(isDrawerExpanded);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getActiveStatus = async () => {
    if (ProfileService.isInRole(UserRoles.renter)) {
      try {
        var result = await HttpClient.doRequest(Methods.GET, endpoints.renter.getActiveStatus, null)

        if (result.ok) {
          var json = await result.json();
          setActive(json.isActive);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getActiveStatus();
  }, []);

  return (
    <div id="codellic-drawer" className={classes.root}>
      <CssBaseline />
      <AppBar
        id="appbar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !isTabletOrMobileDeviceQuery && open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {!isActive && (
            <div className={clsx(classes.inactiveText, { "shift": !isTabletOrMobileDeviceQuery && open })}>
              <Typography
                variant="body1"
                style={{
                  width: "100%",
                  textAlign: "left",
                  color: "white",
                  fontSize: isTabletOrMobileDeviceQuery ? ".8em" : "1em",
                }}
              >
                {TranslationService.l.inschrijvingVerlopen}
              </Typography>
            </div>
          )}
          <div className={classes.toolbarButtons}>
            <Button
              href={
                ProfileService.getLocale() === "en_US"
                  ? "https://www.stadhuis.nl/en/woning-huren"
                  : "https://www.stadhuis.nl/woning-huren"
              }
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              className={classes.goToPropertiesButton}
            >
              {isTabletOrMobileDeviceQuery ? (
                <HomeWorkIcon />
              ) : (
                TranslationService.l.naarWoningaanbod
              )}
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <div id="wrapper-header-logo">
          <img id="header-logo" src="/media/img/shb-logo.png" alt="" />
        </div>
        <Divider />
        <List style={{ padding: 0 }}>
          {ProfileService.isInRole(UserRoles.shb) ? (
            <Fragment>
              {/* Actuele inschrijvingen */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.shb.renter.overview}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.inschrijvingen} />
                </ListItemButton>
              </ListItem>

              {/* Archief inschrijvingen*/}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.shb.archive.overview}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.archief} />
                </ListItemButton>
              </ListItem>

              <Divider />

              {/* Leads */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.shb.lead.overview}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span>
                        <SHBLeadBadge>Huurprijsaanvragen</SHBLeadBadge>
                      </span>
                    }
                  />
                </ListItemButton>
              </ListItem>

              {/* Archief leads */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.shb.lead.rentalPriceCalculationArchiveOverview}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <StorageIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.archief} />
                </ListItemButton>
              </ListItem>

              <Divider />

              {/* Woningen */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.shb.property.overview}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.woningen} />
                </ListItemButton>
              </ListItem>

              <Divider />
            </Fragment>
          ) : null}
          {ProfileService.isInRole(UserRoles.codellic) ? (
            <Fragment>
              {/* Codellic Dashboard */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.codellic.home}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.codellicHome} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          ) : null}
          {ProfileService.isInRole(UserRoles.renter) ? (
            <Fragment>
              {/* Huurder */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.renter.profile}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.huurderProfiel} />
                </ListItemButton>
              </ListItem>

              <Divider />

              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.renter.documents}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.mijnDocumenten} />
                </ListItemButton>
              </ListItem>

              <Divider />

              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.renter.settings}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.instellingen} />
                </ListItemButton>
              </ListItem>

              <Divider />
            </Fragment>
          ) : null}
          {ProfileService.isInRole(UserRoles.landlord) ? (
            <Fragment>
              {/* Verhuurder */}
              <ListItem
                onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
                component={Link}
                className={classes.menuItem}
                to={appRoutes.landlord.home}
                disablePadding
              >
                <ListItemButton className={classes.menuButton}>
                  <ListItemIcon className={classes.menuIcon}>
                    <HomeIcon />
                  </ListItemIcon>
                  <ListItemText primary={TranslationService.l.verhuurderProfiel} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          ) : null}
          <ListItem
            className={classes.menuItem}
            component={Link}
            to=""
            onClick={() => window.location.href = "/Identity/Account/Logout"}
            disablePadding
          >
            <ListItemButton className={classes.menuButton}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={TranslationService.l.logUit} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: !isTabletOrMobileDeviceQuery && open,
        })}
      >
        <div
          onClick={isTabletOrMobileDeviceQuery ? handleDrawerClose : null}
          className={clsx(classes.overlay, {
            [classes.overlayActive]: isTabletOrMobileDeviceQuery && open,
          })}
        />

        <div className={classes.drawerHeader} />
        {props.children}
      </main>
    </div>
  );
}