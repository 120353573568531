import React from "react";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export default class PDFViewer extends React.Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.backend = new props.backend();
  }

  componentDidMount() {
    const { src } = this.props;
    const element = this.viewerRef.current;
    this.backend.init(src, element);
  }

  render() {
    return (
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          zIndex: 2000,
          width: "100%",
          height: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={this.props.callback}
          style={{
            backgroundColor: '#eee',
            position: "fixed",
            right: "35px",
            top: "55px",
            marginLeft: "20px",
            color: "#000",
          }}
          edge="end"
        >
          <CancelIcon  />
        </Button>
        <div
          ref={this.viewerRef}
          id="viewer"
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}
