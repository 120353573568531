import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Container,
} from "@mui/material";
import HttpClient, { Methods } from "../../services/HttpClient";
import ProgressIndicator from "../ProgressIndicator";
import Button from "@mui/material/Button";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import TranslationService from "../../services/TranslationService";

// Icons
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

// Media queries
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "../MediaQueries";
import { endpoints } from "../../constants/Endpoints";
import { makeStyles } from "@mui/styles";

export function ProfileDetailView(props) {
  const isTabletOrMobileDevice = useMediaQuery(MediaQueries.MobileDevice);
  const [isMounted, setMounted] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [isEditing, setEditing] = useState(false);
  const [lastData, setLastData] = useState({});
  const [data, setData] = useState({});
  const formRef = useRef();

  const textFieldVariant = "outlined";

  const styles = {
    editbutton: {
      marginLeft: "20px",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    saveButton: {
      backgroundColor: "#eb008b",
      marginLeft: "20px",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    defaultButton: {
      marginLeft: "20px",
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    profilePicture: {
      borderRadius: "50%",
      width: "100%",
      display: "block",
      margin: "0 auto",
      minWidth: "100px",
      maxWidth: "140px",
      textAlign: "center",
    },
  };

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLastData(v);
        setLoading(false);
      }

      return () => {
        setMounted(false);
      };
    });
  }, [isMounted, props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    sendData().then((s) => {
      if (data.applicationUserLocale !== lastData.applicationUserLocale) {
        window.location.href = "/authentication/login";
      } else {
        getData().then((v) => {
          if (isMounted) {
            setData(v);
            setLastData(v);
            setLoading(false);
            setEditing(false);
          }
        });
      }
    });
  };

  const getData = async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.getProfile,
      null
    );

    if (response.ok) {
      return await response.json();
    }

    // Logout if this call fails.
    window.location.href = "/Identity/Account/Logout";
  };

  const sendData = async () => {
    const response = await HttpClient.doRequest(
      Methods.PUT,
      endpoints.renter.updateProfile,
      data
    );

    if (response.ok) return true;
    else return false;
  };

  const handleDateChange = (name, date) => {
    if (date != null) {
      var _date = new Date(date);
      var year = _date.getFullYear();
      var month = _date.getMonth();
      var day = _date.getDate();
      var newDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

      if (isMounted) {
        setData({ ...data, [name]: newDate.toISOString() });
      }
    } else if (isMounted) {
      setData({ ...data, [name]: null });
    }
  };

  const handleInputChange = (e) => {
    if (isMounted) {
      const { name, value, type } = e.target;
      setData({
        ...data,
        [name]:
          type === "number" || typeof value === "number"
            ? value !== ""
              ? parseInt(value)
              : 0
            : value.trim(),
      });
    }
  };

  const cancelEdit = (_) => {
    setLoading(true);
    setData(lastData);
    setEditing(false);
    setTimeout(() => {
      setLoading(false);
    }, 0);
  };

  return (
    <form
      ref={formRef}
      id="renter-detail-view"
      method="post"
      onSubmit={handleSubmit}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper>
                <Box p={3}>
                  <Grid container>
                    {/* General data */}
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={3}>
                          <img
                            style={styles.profilePicture}
                            src="/media/img/placeholder-person-01.png"
                            alt=""
                          />
                        </Grid> */}
                        <Grid item xs={12} md={9}>
                          <Typography variant="h4">
                            {data.applicationUserFirstName !== null &&
                            data.applicationUserLastName
                              ? data.applicationUserFirstName +
                                " " +
                                data.applicationUserLastName
                              : ""}
                          </Typography>
                          <Typography gutterBottom={true} paragraph={true}>
                            {data.applicationUserEmail}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color={"textSecondary"}
                          >
                            {TranslationService.l.ingeschrevenOp}
                            {moment(data.registrationDate).format("DD-MM-YYYY")}
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            color={"textSecondary"}
                          >
                            {TranslationService.l.ingeschrevenTot}
                            {moment(data.paymentExpiresAt).format("DD-MM-YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Buttons */}
                    <Grid item xs={12} md={6}>
                      <Box display="flex" flexDirection="row-reverse">
                        <div>
                          {isEditing ? (
                            <Fragment>
                              <Button
                                onClick={cancelEdit}
                                variant="contained"
                                style={styles.defaultButton}
                                startIcon={<CancelIcon />}
                                edge="end"
                              >
                                {isTabletOrMobileDevice ? (
                                  <span />
                                ) : (
                                  <span>{TranslationService.l.annuleren}</span>
                                )}
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                style={styles.saveButton}
                                startIcon={<SaveIcon />}
                                edge="end"
                              >
                                {isTabletOrMobileDevice ? (
                                  <span />
                                ) : (
                                  <span>{TranslationService.l.opslaan}</span>
                                )}
                              </Button>
                            </Fragment>
                          ) : (
                            <Button
                              onClick={() => setEditing(true)}
                              variant="contained"
                              style={styles.editbutton}
                              startIcon={<EditIcon />}
                              edge="end"
                              color="primary"
                            >
                              {
                                <span>
                                  {TranslationService.l.bewerkProfiel}
                                </span>
                              }
                            </Button>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        {isLoading ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <ProgressIndicator />
          </div>
        ) : (
          <Container maxWidth={false} disableGutters={true}>
            <Grid container spacing={2}>
              {/* Algemene gegevens */}
              <Grid item md={4}>
                <Paper>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {TranslationService.l.algemeneGegevens}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              disabled={!isEditing}
                              onChange={handleInputChange}
                              variant={textFieldVariant}
                              fullWidth
                              label={TranslationService.l.bedrijfsnaam}
                              name="applicationUserCompanyName"
                              defaultValue={
                                data.applicationUserCompanyName !== null
                                  ? data.applicationUserCompanyName
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled={!isEditing}
                          required
                          onChange={handleInputChange}
                          variant={textFieldVariant}
                          fullWidth
                          label={TranslationService.l.telefoonnummer}
                          name="applicationUserPhoneNumber"
                          defaultValue={
                            data.applicationUserPhoneNumber !== null
                              ? data.applicationUserPhoneNumber
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          disabled={!isEditing}
                          onChange={handleInputChange}
                          variant={textFieldVariant}
                          label={TranslationService.l.nettoInkomenPerMaand}
                          name="netIncomePerMonth"
                          type="number"
                          inputProps={{ min: "0", step: "1" }}
                          defaultValue={
                            data.netIncomePerMonth !== null
                              ? data.netIncomePerMonth
                              : 0
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              {/* Adres */}
              <Grid item md={4}>
                <Paper>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {TranslationService.l.huidigAdres}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={!isEditing}
                          required
                          onChange={handleInputChange}
                          variant={textFieldVariant}
                          label={TranslationService.l.adres}
                          name="street"
                          defaultValue={data.street !== null ? data.street : ""}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          required
                          disabled={!isEditing}
                          onChange={handleInputChange}
                          label={TranslationService.l.huisnummer}
                          variant={textFieldVariant}
                          name="houseNumber"
                          inputProps={{ min: 0, max: 1000000 }}
                          type="number"
                          defaultValue={
                            data.houseNumber !== null ? data.houseNumber : ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          disabled={!isEditing}
                          onChange={handleInputChange}
                          label={TranslationService.l.toevoeging}
                          variant={textFieldVariant}
                          name="addition"
                          defaultValue={
                            data.addition !== null ? data.addition : ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextField
                          disabled={!isEditing}
                          required
                          onChange={handleInputChange}
                          variant={textFieldVariant}
                          label={TranslationService.l.postCode}
                          name="zipCode"
                          defaultValue={
                            data.zipCode !== null ? data.zipCode : ""
                          }
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={!isEditing}
                          required
                          onChange={handleInputChange}
                          variant={textFieldVariant}
                          label={TranslationService.l.plaats}
                          name="city"
                          defaultValue={data.city !== null ? data.city : ""}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              {/* Woonwensen */}
              <Grid item md={4}>
                <Paper>
                  <Box p={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          {TranslationService.l.woonwensen}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>
                                {TranslationService.l.maximumPrijs}
                              </InputLabel>
                              <Select
                                disabled={!isEditing}
                                label={TranslationService.l.maximumPrijs}
                                type="number"
                                name="preferredMaxPrice"
                                value={
                                  data.preferredMaxPrice !== 0
                                    ? data.preferredMaxPrice
                                    : 400
                                }
                                onChange={handleInputChange}
                                fullWidth
                              >
                                <MenuItem value={400}>€ 400</MenuItem>
                                <MenuItem value={500}>€ 500</MenuItem>
                                <MenuItem value={600}>€ 600</MenuItem>
                                <MenuItem value={700}>€ 700</MenuItem>
                                <MenuItem value={800}>€ 800</MenuItem>
                                <MenuItem value={1000}>€ 1000</MenuItem>
                                <MenuItem value={2500}>€ 2500</MenuItem>
                                <MenuItem value={5000}>€ 5000</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>
                                {TranslationService.l.interieur}
                              </InputLabel>
                              <Select
                                disabled={!isEditing}
                                label={TranslationService.l.interieur}
                                name="preferredInterior"
                                value={
                                  data.preferredInterior !== ""
                                    ? data.preferredInterior
                                    : " "
                                }
                                onChange={handleInputChange}
                                fullWidth
                              >
                                <MenuItem value=" ">
                                  {TranslationService.l.geenVoorkeur}
                                </MenuItem>
                                <MenuItem value="Ongemeubileerd">
                                  {TranslationService.l.ongemeubileerd}
                                </MenuItem>
                                <MenuItem value="Gemeubileerd">
                                  {TranslationService.l.gemeubileerd}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>
                                {TranslationService.l.minimumAantalSlaapkamers}
                              </InputLabel>
                              <Select
                                disabled={!isEditing}
                                label={
                                  TranslationService.l.minimumAantalSlaapkamers
                                }
                                type="number"
                                name="preferredMinNumberOfBedrooms"
                                value={
                                  data.preferredMinNumberOfBedrooms !== null
                                    ? data.preferredMinNumberOfBedrooms
                                    : 0
                                }
                                onChange={handleInputChange}
                                fullWidth
                              >
                                <MenuItem value={0}>
                                  {TranslationService.l.geenVoorkeur}
                                </MenuItem>
                                <MenuItem value={-1}>
                                  {TranslationService.l.geenAparteSlaapkamer}
                                </MenuItem>
                                <MenuItem value={1}>+1</MenuItem>
                                <MenuItem value={2}>+2</MenuItem>
                                <MenuItem value={3}>+3</MenuItem>
                                <MenuItem value={4}>+4</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel>
                                {TranslationService.l.stad}
                              </InputLabel>
                              <Select
                                disabled={!isEditing}
                                label={TranslationService.l.stad}
                                name="preferredCity"
                                value={
                                  data.preferredCity !== ""
                                    ? data.preferredCity
                                    : " "
                                }
                                onChange={handleInputChange}
                                fullWidth
                              >
                                <MenuItem value=" ">
                                  {TranslationService.l.geenVoorkeur}
                                </MenuItem>
                                <MenuItem value="Breda">Breda</MenuItem>
                                <MenuItem value="Breda en omgeving">
                                  {TranslationService.l.bredaEnOmgeving}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} lg={6}>
                                <MobileDatePicker
                                  renderInput={(props) => (
                                    <TextField
                                      {...props}
                                      fullWidth
                                      variant={textFieldVariant}
                                    />
                                  )}
                                  disabled={!isEditing}
                                  input={textFieldVariant}
                                  label={TranslationService.l.startdatumHuren}
                                  inputFormat="DD-MM-YYYY"
                                  value={
                                    data.preferredStartOfRentingDate != null
                                      ? moment(data.preferredStartOfRentingDate)
                                      : null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(
                                      "preferredStartOfRentingDate",
                                      date
                                    )
                                  }
                                  componentsProps={{
                                    actionBar: {
                                      actions: ["accept", "clear", "cancel"],
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={!isEditing}
                                  onChange={handleInputChange}
                                  name="wishes"
                                  multiline={true}
                                  minRows={4}
                                  variant={textFieldVariant}
                                  label={TranslationService.l.overigeWensen}
                                  defaultValue={
                                    data.wishes !== null ? data.wishes : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        )}
      </LocalizationProvider>
    </form>
  );
}
