import React from 'react';
import CustomDrawer from './CustomDrawer';
import PageContent from './PageContent';

export function Layout(props) {
    return (
        <CustomDrawer>
            <PageContent outerTheme={props.outerTheme}>
                {props.children}
            </PageContent>
        </CustomDrawer>
    );
}
