import React, { useState, useRef } from "react";
import { styled } from '@mui/material/styles';
import HttpClient, { Methods } from "../../../services/HttpClient";
import { endpoints } from "../../../constants/Endpoints";
import { fileTypes } from "../../../constants/FileTypes";
import TranslationService from "../../../services/TranslationService";
import Swal from "sweetalert2";
import PDFViewer from "../../PDFViewer";
import PDFJSBackend from "../../backends/pdfjs";

// Icons
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircle from "@mui/icons-material/CheckCircle";
import ImageViewer from "../../ImageViewer";

const PREFIX = 'UploadBlock';

const classes = {
  uploadButton: `${PREFIX}-uploadButton`,
  uploadButtonText: `${PREFIX}-uploadButtonText`
};

const Root = styled('form')({
  [`& .${classes.uploadButton}`]: {
    border: "1px #000 solid",
    borderRadius: "4px",
    minHeight: "100px",
    minWidth: "100px",
    width: "100%",
    height: "100%",
    maxWidth: "200px",
    maxHeight: "200px",
    display: "inline-block",
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "transparent",
    color: "#000",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#f8f8f8 !important",
    },
  },
  [`& .${classes.uploadButtonText}`]: {
    fontSize: ".8em",
  },
});

export default function UploadBlock(props) {
  const [uploaded, setUploaded] = useState(props.uploaded);
  const formRef = useRef();

  const uploadFile = async (e) => {
    e.preventDefault();
    let oFormElement = e.target;
    const formData = new FormData(oFormElement);
    formData.append("fileType", props.fileType);
    formData.append("fileCategory", props.fileCategory);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.uploadDocument,
      formData
    );

    if (response.ok) {
      setUploaded(response.ok);
      props.refreshCallback();
    } else {
      formRef.current.reset();
      Swal.fire({
        icon: "error",
        title: TranslationService.l.errorTitle,
        text: TranslationService.l.defaultErrorDesc,
        confirmButtonColor: "#aaa",
      });
    }
  };

  const downloadFile = () => {
    HttpClient.doRequest(
      Methods.GET,
      endpoints.document.downloadRenterDocument + props.fileType
    ).then((r) => {
      r.blob().then((b) => {
        setFile(b);
      });
    });
  };

  const handleOnClick = (_) => {
    if (!uploaded) document.getElementById(`upload-${props.fileType}`).click();
    else downloadFile();
  };

  const [file, setFile] = useState(null);

  return (
    <Root
      encType="multipart/form-data"
      onSubmitCapture={uploadFile}
      method="post"
      ref={formRef}
    >
      {file !== null && (
        file.type === "application/pdf" ? (
          <PDFViewer
            backend={PDFJSBackend}
            callback={() => setFile(null)}
            src={URL.createObjectURL(file)}
          />
        ) : (
          <ImageViewer
            callback={() => setFile(null)}
            src={URL.createObjectURL(file)}
          />
        )
      )}
      <input
        onChange={() => {
          // Submit form using the formRef.
          formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
        }}
        disabled={uploaded}
        style={{ display: "none" }}
        type="file"
        name="FileUpload.FormFile"
        accept=".pdf,.jpeg,.jpg"
        id={`upload-${props.fileType}`}
      />

      <div onClick={handleOnClick} className={classes.uploadButton}>
        <div>
          {props.uploaded ? (
            <CheckCircle
              style={{ color: "#50bc50", width: "100%", fontSize: "4em" }}
            />
          ) : (
            <CloudUploadIcon style={{ width: "100%", fontSize: "4em" }} />
          )}
          <hr />
          <div className={`${classes.uploadButtonText} text-center`}>
            {TranslationService.l[fileTypes[props.fileType]]}
          </div>
        </div>
      </div>
    </Root>
  );
}
