import React, { useState, useEffect, useCallback, useMemo } from "react";
import MaterialTable, {
  MTableEditRow,
  MTableToolbar,
} from "@material-table/core";
import HttpClient, { Methods } from "../../../../services/HttpClient";
import * as moment from "moment";
import { Grid, Button, Typography, Box } from "@mui/material";
import { endpoints } from "../../../../constants/Endpoints";
import CodellicAction from "../../../CodellicAction";

// Icons
import RefreshIcon from "@mui/icons-material/Refresh";

export function PropertyTable() {
  const columns = useMemo(
    () => [
      {
        title: "Uitgelicht",
        width: 10,
        field: "extensionIsHighlighted",
        type: "boolean",
        editable: "onUpdate",
      },
      {
        title: "Straat",
        width: 250,
        field: "street",
        editable: "never",
      },
      {
        title: "Nr.",
        width: 20,
        field: "number",
        type: "numeric",
        editable: "never",
      },
      {
        title: "Toevoeging",
        width: 20,
        field: "addition",
        type: "numeric",
        editable: "never",
      },
      {
        title: "Stad",
        field: "city",
        editable: "never",
      },
      {
        title: "Prijs",
        field: "price",
        editable: "never",
        render: (rowData) => <span>€ {rowData.price}</span>,
      },
      {
        title: "Ingangsdatum",
        field: "startDateAvailability",
        editable: "never",
        render: (rowData) => (
          <span>
            {moment(rowData.startDateAvailability)
              .format("DD-MM-YYYY")
              .toString()}
          </span>
        ),
      },
    ],
    []
  );

  const refreshData = () => {
    setLoading(true);
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });
  };

  const styles = {
    exportToCsvButton: {
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    exportToCsvLink: {
      color: "white",
      textDecoration: "none",
    },
    newButton: {
      backgroundColor: "#eb008b",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    refreshButton: {
      backgroundColor: "#f68121",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      marginLeft: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
  };

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.properties.base,
      null
    );
    return await response.json();
  }, []);

  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [isMounted, setMounted] = useState(true);

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [isMounted, getData]);

  const updateData = async (newData) => {
    await HttpClient.doRequest(
      Methods.POST,
      endpoints.properties.base,
      newData
    );
  };

  const actions = [];

  const components = {
    EditRow: (props) => {
      const propsCopy = { ...props };
      return <MTableEditRow {...propsCopy} />;
    },
    Action: (props) => <CodellicAction {...props} />,
    Toolbar: (props) => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box paddingLeft={3} paddingRight={3} paddingTop={3}>
              <Grid container>
                {/* General data */}
                <Grid item xs={12} md={4}>
                  <Typography variant="h6" color="primary">
                    Huidig woningaanbod
                  </Typography>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} md={8}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        variant="contained"
                        style={styles.refreshButton}
                        startIcon={<RefreshIcon />}
                        onClick={refreshData}
                        edge="end"
                      >
                        <span>Vernieuw</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Toolbar */}
          <Grid item xs={12}>
            <Box paddingBottom={3}>
              <MTableToolbar {...props} gutters={false} />
            </Box>
          </Grid>
        </Grid>
      );
    },
  };

  const editable = {
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve) => {
        updateData(newData).then((_) => {
          setLoading(true);
          getData().then((v) => {
            setData(v);
            setLoading(false);
          });
        });
        resolve();
      }),
  };

  const localization = {
    header: {
      actions: "Acties",
    },
    toolbar: {
      searchPlaceholder: "Zoeken",
    },
  };

  const options = {
    headerStyle: {
      padding: "16px",
    },
    cellStyle: {
      padding: "16px",
    },
    searchFieldAlignment: "left",
    searchFieldStyle: {
      marginLeft: "-16px",
    },
  };

  return (
    <MaterialTable
      title=""
      actions={actions}
      columns={columns}
      components={components}
      data={data}
      editable={editable}
      isLoading={isLoading}
      localization={localization}
      options={options}
    />
  );
}
