import React, { Fragment } from "react";
import { Grid, Button, Divider } from "@mui/material";
import TranslationService from "../services/TranslationService";
import { fileTypes } from "../constants/FileTypes";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Swal from "sweetalert2";
import { messageConstants } from "../constants/MessageConstants";

const styles = {
  checkCircleIcon: {
    maxHeight: "20px",
    marginRight: "10px",
    color: "#008000",
  },
  cancelIcon: {
    maxHeight: "20px",
    marginRight: "10px",
    color: "#ec1c24",
  },
  hiddenButton: {
    visibility: "hidden",
  },
  downloadLink: {
    cursor: "pointer",
  },
};

export default function Document(props) {
  const deleteAlert = (_) => {
    Swal.fire({
      title: "",
      text: TranslationService.l.weetJeHetzeker,
      icon: messageConstants.icons.warning,
      showCancelButton: true,
      confirmButtonColor: "#ec2027",
      confirmButtonText: "Verwijder",
    }).then((result) => {
      if (result.value) {
        props.deleteCallback(props.type);
      }
    })
  };

  return (
    <Fragment>
      <Divider />
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            {!props.hasBeenUploaded ? (
              <span>
                <CancelIcon style={styles.cancelIcon} />
                {TranslationService.l[fileTypes[props.type]]}
              </span>
            ) : (
              <span
                style={styles.downloadLink}
                onClick={(_) => props.downloadCallback(props.type)}
                className="document-viewLink"
              >
                <CheckCircleIcon style={styles.checkCircleIcon} />
                {TranslationService.l[fileTypes[props.type]]}
              </span>
            )}
          </Grid>
          <Grid item>
            {props.hasBeenUploaded ? (
              <Button onClick={deleteAlert}>Verwijderen</Button>
            ) : (
              <Button style={styles.hiddenButton}>Verwijderen</Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
