import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  TextField,
  Grid,
  Typography,
  Paper,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Container,
  FormControlLabel,
} from "@mui/material";
import HttpClient, { Methods } from "../../../services/HttpClient";
import ProgressIndicator from "../../ProgressIndicator";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import TranslationService from "../../../services/TranslationService";
import PDFJSBackend from "../../backends/pdfjs";
import { LocalizationProvider } from '@mui/x-date-pickers'

// Icons
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import MailIcon from "@mui/icons-material/Mail";
import RedoIcon from "@mui/icons-material/Redo";

// Media queries
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "../../MediaQueries";
import { appRoutes } from "../../../constants/AppRoutes";
import { endpoints } from "../../../constants/Endpoints";
import { useRef } from "react";
import Document from "../../Document";
import { fileTypes } from "../../../constants/FileTypes";
import Swal from "sweetalert2";
import PDFViewer from "../../PDFViewer";
import ImageViewer from "../../ImageViewer";

export function RenterDetailView(props) {
  const isTabletOrMobileDevice = useMediaQuery(MediaQueries.MobileDevice);
  const [isMounted, setMounted] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const history = useHistory();
  const formRef = useRef();

  const textFieldVariant = "outlined";

  const styles = {
    defaultButton: {
      marginLeft: "20px",
      marginBottom: "20px",
      backgroundColor: "#595959",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    saveButton: {
      backgroundColor: "#eb008b",
      marginLeft: "20px",
      marginBottom: "20px",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
    mailButton: {
      marginLeft: "20px",
      marginBottom: "20px",
      backgroundColor: "#ec2027",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "yellow !important",
      },
    },
    inactive: {
      color: "#ec2027",
      fontWeight: "bold",
    },
    active: {
      color: "green",
      fontWeight: "bold",
    },
    profilePicture: {
      borderRadius: "50%",
      width: "100%",
      display: "block",
      margin: "0 auto",
      minWidth: "100px",
      maxWidth: "140px",
      textAlign: "center",
    },
    alignRight: {
      textAlign: "right",
    },
  };

  const getData = useCallback(async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.base + props.match.params.id,
      null
    );
    return await response.json();
  }, [props]);

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLoading(false);
      }

      return () => {
        setMounted(false);
      };
    });
  }, [isMounted, getData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    sendData().then((s) => {
      if (s) {
        history.push(appRoutes.shb.renter.overview);
      } else {
        setLoading(false);
      }
    });
  };

  const sendData = async () => {
    const response = await HttpClient.doRequest(
      Methods.PUT,
      endpoints.renter.base + props.match.params.id,
      data
    );

    if (response.ok) return true;
    else return false;
  };

  const deregister = async () => {
    const response = await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.renter.base + props.match.params.id,
      null
    );

    if (response.ok) {
      history.push(appRoutes.shb.renter.overview);
    }
  };

  const handleDateChange = (name, date) => {
    if (date != null) {
      var _date = new Date(date);
      var year = _date.getFullYear();
      var month = _date.getMonth();
      var day = _date.getDate();
      var newDate = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

      if (isMounted) {
        setData({ ...data, [name]: newDate.toISOString() });
      }
    } else if (isMounted) {
      setData({ ...data, [name]: null });
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (isMounted) {
      setData({ ...data, [name]: checked });
    }
  };

  const handleInputChange = (e) => {
    if (isMounted) {
      const { name, value, type } = e.target;

      setData({
        ...data,
        [name]:
          type === "number" || typeof value === "number"
            ? value !== ""
              ? parseInt(value)
              : 0
            : value.trim(),
      });
    }
  };

  const deleteFileDocument = async (fileType) => {
    const response = await HttpClient.doRequest(
      Methods.DELETE,
      endpoints.document.deleteRenterDocument + props.match.params.id,
      { fileType: fileType }
    );

    if (response.ok) {
      setData({
        ...data,
        fileDocuments: data.fileDocuments.filter(
          (x) => x.fileType !== fileType
        ),
      });
    }
  };

  const sendAccountActivationEmail = async (email) => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.sendAccountActivationEmail + "?renterId=" + data.id + "&email=" + email,
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }
    setLoading(false);
  };

  const downloadFile = (fileType) => {
    HttpClient.doRequest(
      Methods.POST,
      endpoints.document.shbDownloadRenterDocument,
      {
        fileType: fileType,
        renterId: Number.parseInt(props.match.params.id),
      }
    ).then((r) => {
      r.blob().then((b) => {
        setFile(b);
      });
    });
  };

  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const renewSubscription = async () => {
    setLoading(true);

    const response = await HttpClient.doRequest(
      Methods.POST,
      endpoints.renter.renewSubscription,
      { renterId: data.id, newDate: selectedDate.toISOString(true) }
    );

    const message = await response.json();

    if (response.ok) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: message.icon,
        title: message.title,
      });
    } else {
      Swal.fire({
        icon: message.icon,
        title: message.title,
        text: message.content,
      });
    }

    var newData = await getData();
    setData(newData);

    setLoading(false);
  };

  const startSubscriptionRenewal = () => {
    setSelectedDate(null);
    setShowDateModal(true);
  };

  const [file, setFile] = useState(null);

  return (
    <Fragment>
      {file !== null ? (
        file.type === "application/pdf" ? (
          <PDFViewer
            backend={PDFJSBackend}
            callback={() => setFile(null)}
            src={URL.createObjectURL(file)}
          />
        ) : (
          <ImageViewer
            callback={() => setFile(null)}
            src={URL.createObjectURL(file)}
          />
        )
      ) : null}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <form
          id="renter-detail-view"
          ref={formRef}
          method="post"
          onSubmit={handleSubmit}
        >
          {isLoading ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ProgressIndicator />
            </div>
          ) : (
            <>
              <Container maxWidth={false} disableGutters={true}>
                <Grid container>
                  {/* Buttons */}
                  <Grid item xs={12} style={styles.alignRight}>
                    <Box pt={3}>
                      <Grid container>
                        <Grid item xs={12}>
                          {!data.applicationUserIsActivated ? (
                            /* Send activation mail */
                            <Button
                              onClick={(_) =>
                                Swal.fire({
                                  title: "",
                                  text: `Activatiemail versturen naar?`,
                                  icon: "info",
                                  showCancelButton: true,
                                  confirmButtonColor: "#eb008b",
                                  confirmButtonText: "Versturen",
                                  input: "text",
                                  inputPlaceholder: "E-mailadres",
                                  inputValue: data.applicationUserEmail,
                                  inputValidator: (email) => {
                                    if (!email) {
                                      return "E-mailadres is verplicht";
                                    }
                                    return "";
                                  }
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    sendAccountActivationEmail(result.value);
                                  }
                                })
                              }
                              variant="contained"
                              style={styles.mailButton}
                              startIcon={<MailIcon />}
                            >
                              {isTabletOrMobileDevice ? (
                                <span />
                              ) : (
                                <span>Stuur activatie mail</span>
                              )}
                            </Button>
                          ) : null}

                          {/* Deregistration */}
                          <Button
                            onClick={(_) => {
                              Swal.fire({
                                title: "Uitschrijven",
                                text:
                                  "Weet je zeker dat je de huurder wilt uitschrijven?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#ec2027",
                                confirmButtonText: "Uitschrijven",
                              }).then((result) => {
                                if (result.value) {
                                  setLoading(true);
                                  deregister();
                                }
                              });
                            }}
                            variant="contained"
                            style={styles.defaultButton}
                            startIcon={<DeleteIcon />}
                          >
                            {isTabletOrMobileDevice ? (
                              <span />
                            ) : (
                              <span>Uitschrijven</span>
                            )}
                          </Button>

                          {/* Extend registration */}
                          <Button
                            onClick={(_) => {
                              setShowDateModal(true);
                            }}
                            variant="contained"
                            style={styles.defaultButton}
                            startIcon={<RedoIcon />}
                          >
                            {isTabletOrMobileDevice ? (
                              <span />
                            ) : (
                              <span>Verleng inschrijving</span>
                            )}
                          </Button>

                          {/* Save */}
                          <Button
                            type="submit"
                            variant="contained"
                            style={styles.saveButton}
                            startIcon={<SaveIcon />}
                            edge="end"
                          >
                            {isTabletOrMobileDevice ? (
                              <span />
                            ) : (
                              <span>Opslaan</span>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Paper>
                      <Box p={3}>
                        <Grid container spacing={2}>
                          {/* General data */}
                          <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={9}>
                                <Typography variant="h4">
                                  {data.applicationUserFirstName !== null &&
                                    data.applicationUserLastName
                                    ? data.applicationUserFirstName +
                                    " " +
                                    data.applicationUserLastName
                                    : ""}
                                </Typography>
                                <Typography
                                  gutterBottom={true}
                                  paragraph={true}
                                >
                                  {data.applicationUserEmail}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color={"textSecondary"}
                                >
                                  {TranslationService.l.ingeschrevenSinds}
                                  {moment(data.registrationDate).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color={"textSecondary"}
                                >
                                  {TranslationService.l.ingeschrevenTot}
                                  {moment(data.paymentExpiresAt).format(
                                    "DD-MM-YYYY"
                                  )}
                                </Typography>
                                <Typography>
                                  {data.applicationUserIsActivated ? (
                                    <span style={styles.active}>
                                      Account is geactiveerd
                                    </span>
                                  ) : (
                                    <span style={styles.inactive}>
                                      Account is nog niet geactiveerd
                                    </span>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Grid container>
                              <Grid item xs={12}>
                                <TextField
                                  onChange={handleInputChange}
                                  name="shbNotes"
                                  multiline={true}
                                  minRows={5}
                                  variant={textFieldVariant}
                                  label="SHB notities"
                                  defaultValue={
                                    data.shbNotes ? data.shbNotes : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>

              <Container maxWidth={false} disableGutters={true}>
                <Grid container spacing={2}>
                  {/* Algemene gegevens, instellingen */}
                  <Grid item md={4}>
                    <Grid container>
                      <Grid item>
                        <Paper>
                          <Box p={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography gutterBottom={false} variant="h6">
                                      {TranslationService.l.algemeneGegevens}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TextField
                                      onChange={handleInputChange}
                                      variant={textFieldVariant}
                                      fullWidth
                                      label={TranslationService.l.bedrijfsnaam}
                                      name="applicationUserCompanyName"
                                      defaultValue={
                                        data.applicationUserCompanyName !== null
                                          ? data.applicationUserCompanyName
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      required
                                      onChange={handleInputChange}
                                      variant={textFieldVariant}
                                      fullWidth
                                      label={TranslationService.l.voornaam}
                                      name="applicationUserFirstName"
                                      defaultValue={
                                        data.applicationUserFirstName !== null
                                          ? data.applicationUserFirstName
                                          : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      required
                                      onChange={handleInputChange}
                                      variant={textFieldVariant}
                                      fullWidth
                                      label={TranslationService.l.achternaam}
                                      name="applicationUserLastName"
                                      defaultValue={
                                        data.applicationUserLastName !== null
                                          ? data.applicationUserLastName
                                          : ""
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      required
                                      onChange={handleInputChange}
                                      variant={textFieldVariant}
                                      fullWidth
                                      label={TranslationService.l.telefoonnummer}
                                      name="applicationUserPhoneNumber"
                                      defaultValue={
                                        data.applicationUserPhoneNumber !== null
                                          ? data.applicationUserPhoneNumber
                                          : ""
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      onChange={handleInputChange}
                                      variant={textFieldVariant}
                                      label={
                                        TranslationService.l.nettoInkomenPerMaand
                                      }
                                      name="netIncomePerMonth"
                                      type="number"
                                      inputProps={{ min: "0", step: "1" }}
                                      defaultValue={
                                        data.netIncomePerMonth !== null
                                          ? data.netIncomePerMonth
                                          : 0
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                      <Grid item md={12}>
                        {/* Instellingen */}
                        <Paper>
                          <Box p={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Instellingen
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                >
                                  <InputLabel>Taal</InputLabel>
                                  <Select
                                    label="Interieur"
                                    name="applicationUserLocale"
                                    value={
                                      data.applicationUserLocale !== null
                                        ? data.applicationUserLocale
                                        : "nl_NL"
                                    }
                                    onChange={handleInputChange}
                                    fullWidth
                                  >
                                    <MenuItem value="nl_NL">
                                      Nederlands
                                    </MenuItem>
                                    <MenuItem value="en_US">Engels</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={data.doesReceiveEmails}
                                      onChange={handleCheckboxChange}
                                      name="doesReceiveEmails"
                                      color="primary"
                                    />
                                  }
                                  label="Geabonneerd op e-mail nieuw woningaanbod"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Adres, woonwensen */}
                  <Grid item md={4}>
                    <Grid container>
                      {/* Huidig adres */}
                      <Grid item>
                        <Paper>
                          <Box p={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  {TranslationService.l.huidigAdres}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  onChange={handleInputChange}
                                  variant={textFieldVariant}
                                  label={TranslationService.l.adres}
                                  name="street"
                                  defaultValue={
                                    data.street !== null ? data.street : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                  required
                                  onChange={handleInputChange}
                                  label={TranslationService.l.huisnummer}
                                  variant={textFieldVariant}
                                  inputProps={{ min: 0, max: 1000000 }}
                                  type="number"
                                  name="houseNumber"
                                  defaultValue={
                                    data.houseNumber !== null
                                      ? data.houseNumber
                                      : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                  onChange={handleInputChange}
                                  label={TranslationService.l.toevoeging}
                                  variant={textFieldVariant}
                                  name="addition"
                                  defaultValue={
                                    data.addition !== null ? data.addition : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12} md={6} lg={4}>
                                <TextField
                                  required
                                  onChange={handleInputChange}
                                  variant={textFieldVariant}
                                  label={TranslationService.l.postCode}
                                  name="zipCode"
                                  defaultValue={
                                    data.zipCode !== null ? data.zipCode : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  onChange={handleInputChange}
                                  variant={textFieldVariant}
                                  label={TranslationService.l.plaats}
                                  name="city"
                                  defaultValue={
                                    data.city !== null ? data.city : ""
                                  }
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                      {/* Woonwensen */}
                      <Grid item>
                        <Paper>
                          <Box p={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <Typography variant="h6">
                                      {TranslationService.l.woonwensen}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <InputLabel>
                                        {TranslationService.l.maximumPrijs}
                                      </InputLabel>
                                      <Select
                                        label={TranslationService.l.maximumPrijs}
                                        type="number"
                                        name="preferredMaxPrice"
                                        value={
                                          data.preferredMaxPrice !== 0
                                            ? data.preferredMaxPrice
                                            : 400
                                        }
                                        onChange={handleInputChange}
                                        fullWidth
                                      >
                                        <MenuItem value={400}>€ 400</MenuItem>
                                        <MenuItem value={500}>€ 500</MenuItem>
                                        <MenuItem value={600}>€ 600</MenuItem>
                                        <MenuItem value={700}>€ 700</MenuItem>
                                        <MenuItem value={800}>€ 800</MenuItem>
                                        <MenuItem value={1000}>€ 1000</MenuItem>
                                        <MenuItem value={2500}>€ 2500</MenuItem>
                                        <MenuItem value={5000}>€ 5000</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <InputLabel>
                                        {TranslationService.l.interieur}
                                      </InputLabel>
                                      <Select
                                        label={TranslationService.l.interieur}
                                        name="preferredInterior"
                                        value={
                                          data.preferredInterior !== ""
                                            ? data.preferredInterior
                                            : " "
                                        }
                                        onChange={handleInputChange}
                                        fullWidth
                                      >
                                        <MenuItem value=" ">
                                          {TranslationService.l.geenVoorkeur}
                                        </MenuItem>
                                        <MenuItem value="Ongemeubileerd">
                                          {TranslationService.l.ongemeubileerd}
                                        </MenuItem>
                                        <MenuItem value="Gemeubileerd">
                                          {TranslationService.l.gemeubileerd}
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <InputLabel>
                                        {
                                          TranslationService.l
                                            .minimumAantalSlaapkamers
                                        }
                                      </InputLabel>
                                      <Select
                                        label={
                                          TranslationService.l
                                            .minimumAantalSlaapkamers
                                        }
                                        type="number"
                                        name="preferredMinNumberOfBedrooms"
                                        value={
                                          data.preferredMinNumberOfBedrooms !== null
                                            ? data.preferredMinNumberOfBedrooms
                                            : 0
                                        }
                                        onChange={handleInputChange}
                                        fullWidth
                                      >
                                        <MenuItem value={0}>
                                          {TranslationService.l.geenVoorkeur}
                                        </MenuItem>
                                        <MenuItem value={-1}>
                                          {
                                            TranslationService.l
                                              .geenAparteSlaapkamer
                                          }
                                        </MenuItem>
                                        <MenuItem value={1}>+1</MenuItem>
                                        <MenuItem value={2}>+2</MenuItem>
                                        <MenuItem value={3}>+3</MenuItem>
                                        <MenuItem value={4}>+4</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <InputLabel>
                                        {TranslationService.l.stad}
                                      </InputLabel>
                                      <Select
                                        label={TranslationService.l.stad}
                                        name="preferredCity"
                                        value={
                                          data.preferredCity !== ""
                                            ? data.preferredCity
                                            : " "
                                        }
                                        onChange={handleInputChange}
                                        fullWidth
                                      >
                                        <MenuItem value=" ">
                                          {TranslationService.l.geenVoorkeur}
                                        </MenuItem>
                                        <MenuItem value="Breda">Breda</MenuItem>
                                        <MenuItem value="Breda en omgeving">
                                          {TranslationService.l.bredaEnOmgeving}
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    <MobileDatePicker
                                      renderInput={(props) => <TextField fullWidth {...props} />}
                                      inputVariant={textFieldVariant}
                                      label={TranslationService.l.startdatumHuren}
                                      inputFormat="DD-MM-YYYY"
                                      value={
                                        data.preferredStartOfRentingDate != null
                                          ? moment(data.preferredStartOfRentingDate)
                                          : null
                                      }
                                      onChange={(date) =>
                                        handleDateChange(
                                          "preferredStartOfRentingDate",
                                          date
                                        )
                                      }
                                      componentsProps={{
                                        actionBar: {
                                          actions: ['accept', 'clear', 'cancel']
                                        }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <TextField
                                      onChange={handleInputChange}
                                      name="wishes"
                                      multiline={true}
                                      minRows={4}
                                      variant={textFieldVariant}
                                      label={TranslationService.l.overigeWensen}
                                      defaultValue={
                                        data.wishes !== null ? data.wishes : ""
                                      }
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Documenten */}
                  <Grid item xs={12} md={4}>
                    <Paper>
                      <Box p={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">Documenten</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={(_) =>
                                Swal.fire({
                                  title: "",
                                  text: 'Bestanden per e-mail versturen naar: ',
                                  icon: "info",
                                  showLoaderOnConfirm: true,
                                  showCancelButton: true,
                                  confirmButtonColor: "#eb008b",
                                  confirmButtonText: "Versturen",
                                  cancelButtonText: "Annuleren",
                                  allowOutsideClick: () => !Swal.isLoading(),
                                  focusConfirm: false,
                                  html: `
                                  <div style='display:inline-block'>
                                    <div>
                                      <input placeholder="E-mailadres" id="docmail-email" type="email" class="swal2-input"
                                        style="margin:10px 2px">
                                    </div>
                                    <div>
                                      <input placeholder="Achternaam" id="docmail-lastname" class="swal2-input" style="margin:10px 2px">
                                    </div>
                                    <div>
                                      <select value="meneer" id="docmail-salutation" class="swal2-input" style="margin:10px 2px;width:100%">
                                        <option value="meneer">Meneer</option>
                                        <option value="mevrouw">Mevrouw</option>
                                        <select>
                                    </div>
                                  </div>
                                      `,
                                  preConfirm: () => {
                                    try {
                                      var email = document.getElementById(
                                        "docmail-email"
                                      ).value;

                                      var lastName = document.getElementById(
                                        "docmail-lastname"
                                      ).value;

                                      var salutation = document.getElementById(
                                        "docmail-salutation"
                                      ).value;

                                      if (
                                        email === "" ||
                                        lastName === "" ||
                                        salutation === ""
                                      ) {
                                        throw new Error(
                                          "Niet alle verplichte velden zijn ingevuld."
                                        );
                                      }

                                      return HttpClient.doRequest(
                                        Methods.POST,
                                        endpoints.renter.sendFilesToEmail,
                                        {
                                          email: email,
                                          lastName: lastName,
                                          salutation: salutation,
                                          renterId: data.id,
                                        }
                                      ).then((r) => {
                                        if (!r.ok) {
                                          Swal.showValidationMessage(
                                            "E-mail kon niet worden verstuurd.<br />Mogelijk heeft de huurder geen bestanden of zijn er corrupte bestanden ge&uuml;pload."
                                          );
                                        }
                                      });
                                    } catch (e) {
                                      Swal.showValidationMessage(e.message);
                                    }
                                  },
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    Swal.fire({
                                      title: `E-mail is verstuurd`,
                                    });
                                  }
                                })
                              }
                              variant="contained"
                              style={{ ...styles.mailButton, marginLeft: 0 }}
                              startIcon={<MailIcon />}
                            >
                              {isTabletOrMobileDevice ? (
                                <span />
                              ) : (
                                <span>Mail bestanden</span>
                              )}
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            {fileTypes.map((file, index) => {
                              return (
                                <Document
                                  key={index}
                                  type={index}
                                  downloadCallback={downloadFile}
                                  deleteCallback={deleteFileDocument}
                                  hasBeenUploaded={
                                    data.fileDocuments !== undefined
                                      ? data.fileDocuments.filter(
                                        (x) => x.fileType === index
                                      ).length > 0
                                      : false
                                  }
                                />
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Container>
            </>
          )}
        </form>
        <MobileDatePicker
          renderInput={() => null}
          open={showDateModal}
          onOpen={startSubscriptionRenewal}
          onClose={() => setShowDateModal(false)}
          onAccept={renewSubscription}
          onChange={setSelectedDate}
          value={selectedDate}
          componentsProps={{
            actionBar: {
              actions: ['accept', 'cancel']
            }
          }}
        />
      </LocalizationProvider>
    </Fragment>
  );
}
