import React from 'react';
import { CircularProgress } from '@mui/material';

export default function ProgressIndicator() {
    return (
        <div className="text-center m-3">
            <CircularProgress />
        </div >
    );
}
