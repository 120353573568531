import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Paper,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Container,
  FormControlLabel,
} from "@mui/material";
import HttpClient, { Methods } from "../../services/HttpClient";
import ProgressIndicator from "../ProgressIndicator";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TranslationService from "../../services/TranslationService";

// Icons
import SaveIcon from "@mui/icons-material/Save";

// Media queries
import { useMediaQuery } from "react-responsive";
import { MediaQueries } from "../MediaQueries";
import { endpoints } from "../../constants/Endpoints";
import ProfileService from "../../services/ProfileService";

export function SettingsView(props) {
  const isTabletOrMobileDevice = useMediaQuery(MediaQueries.MobileDevice);
  const [isMounted, setMounted] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [lastData, setLastData] = useState({});
  const [data, setData] = useState({});
  const formRef = useRef();

  const styles = {
    editbutton: {
      marginLeft: "20px",
      backgroundColor: "grey",
      color: "#ffffff",
      "&:hover": {
        color: "#d01717",
      },
    },
    saveButton: {
      backgroundColor: "#eb008b",
      marginLeft: "20px",
      color: "#ffffff",
      "&:hover": {
        color: "#eb008b",
      },
    },
  };

  useEffect(() => {
    getData().then((v) => {
      if (isMounted) {
        setData(v);
        setLastData(v);
        setLoading(false);
      }

      return () => {
        setMounted(false);
      };
    });
  }, [isMounted, props]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    sendData().then((s) => {
      if (data.applicationUserLocale !== lastData.applicationUserLocale) {
        ProfileService.setLocale(data.applicationUserLocale);
        window.location.reload();
      } else {
        getData().then((v) => {
          if (isMounted) {
            setData(v);
            setLastData(v);
            setLoading(false);
          }
        });
      }
    });
  };

  const getData = async () => {
    const response = await HttpClient.doRequest(
      Methods.GET,
      endpoints.renter.getProfile,
      null
    );

    if (response.ok) {
      return await response.json();
    }

    // Logout if this call fails.
    window.location.href = "/Identity/Account/Logout";
  };

  const sendData = async () => {
    const response = await HttpClient.doRequest(
      Methods.PUT,
      endpoints.renter.updateSettings,
      data
    );

    if (response.ok) return true;
    else return false;
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (isMounted) {
      setData({ ...data, [name]: checked });
    }
  };

  const handleInputChange = (e) => {
    if (isMounted) {
      const { name, value, type } = e.target;
      setData({
        ...data,
        [name]:
          type === "number" ? (value !== "" ? parseInt(value) : 0) : value,
      });
    }
  };

  return (
    <form
      ref={formRef}
      id="renter-settings-view"
      method="post"
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <ProgressIndicator />
        </div>
      ) : (
        <Container maxWidth={false} disableGutters={true}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={8} sm={12}>
              <Paper>
                <Box p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6" gutterBottom>
                        {TranslationService.l.instellingen}
                      </Typography>
                      <Typography variant="body2">
                        {TranslationService.l.settingsAVGTekst}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>{TranslationService.l.taal}</InputLabel>
                        <Select
                          label={TranslationService.l.taal}
                          name="applicationUserLocale"
                          value={
                            data.applicationUserLocale !== null
                              ? data.applicationUserLocale
                              : ""
                          }
                          onChange={handleInputChange}
                          fullWidth
                        >
                          <MenuItem value="nl_NL">
                            {TranslationService.l.nederlands}
                          </MenuItem>
                          <MenuItem value="en_US">
                            {TranslationService.l.engels}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data.doesReceiveEmails}
                            onChange={handleCheckboxChange}
                            name="doesReceiveEmails"
                            color="primary"
                          />
                        }
                        label={
                          TranslationService.l
                            .geabonneerdOpEmailNieuwWoningaanbod
                        }
                      />
                      <Grid item xs={12} style={{ textAlign: "end" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={styles.saveButton}
                          startIcon={<SaveIcon />}
                          edge="end"
                        >
                          {isTabletOrMobileDevice ? (
                            <span />
                          ) : (
                            <span>{TranslationService.l.opslaan}</span>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </form>
  );
}
