import React from 'react';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'

export default function PageContent(props) {
    const innerTheme = createTheme({
        ...props.outerTheme,
        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        marginTop: '20px'
                    }
                }
            },
        }
    });

    return (
        <ThemeProvider theme={innerTheme}>
            {props.children}
        </ThemeProvider>
    );
}