import React from 'react';
import { LeadTable } from './overview/LeadTable';

export function LeadOverview() {
    return (
        <div id='page-lead-overview'>
            <div className="content-wrapper">
                <section className='content-body'>
                    <LeadTable />
                </section>
            </div>
        </div>
    );
}