import ProfileService from "./ProfileService";

export const Methods = {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
};

class HttpClient {
    constructor() {
        this.refreshPromise = null; // Track the ongoing refresh request.
    }

    isTokenExpired(profile) {
        const tokenExpiry = new Date(profile.expiryDate);

        return new Date() > tokenExpiry;
    }

    async doRequest(method = Methods.GET, url, body) {
        // If we are refreshing the token, we add this request to the queue.
        if (this.refreshPromise) {
            await this.refreshPromise;
        }

        const profile = ProfileService.getUserDetails();

        const headers = {};

        if (profile !== null) {
            if (this.isTokenExpired(profile)) {
                await this.refreshToken(profile);
            }

            headers["Authorization"] = `Bearer ${profile.token}`;
        }

        if (body && !(body instanceof FormData)) {
            body = JSON.stringify(body);
            headers["Content-Type"] = "application/json";
        }

        const options = {
            method: method,
            body: body,
            headers: headers
        };

        const response = await fetch(url, options);

        return response;
    }

    async refreshToken(currentProfile) {
        this.refreshPromise = new Promise(async (resolve, reject) => {
            try {
                const refreshResponse = await fetch("/api/Auth/RefreshToken", {
                    method: "POST",
                    body: JSON.stringify({
                        refreshToken: currentProfile.refreshToken,
                        token: currentProfile.token
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (!refreshResponse.ok) {
                    // Handle refresh failure
                    // You can choose to log out the user or take other appropriate actions
                    throw new Error("Token refresh failed.");
                }

                const refreshResult = await refreshResponse.json();

                ProfileService.updateTokenInfo(refreshResult, currentProfile);

                // Resolve the refresh promise
                resolve();

            } catch (error) {
                // Reject the refresh promise
                reject(error);

                // Logout if refresh fails.
                window.location.href = "/Identity/Account/Logout";
            } finally {
                this.refreshPromise = null;
            }
        });

        // Wait for the refresh process to finish.
        await this.refreshPromise;
    }
}

export default new HttpClient();
