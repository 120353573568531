import React, { useState, useEffect, Fragment } from "react";
import {
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { Badge } from "@mui/material";
import ProfileService from "../services/ProfileService";

export default function SHBLeadBadge(props) {
  const [leadCount, setLeadCount] = useState(0);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(window.location.origin + "/hubs/shb?access_token=" + ProfileService.getUserDetails().token)
      .configureLogging(
        process.env.NODE_ENV === "production" ? LogLevel.Error : LogLevel.Debug
      )
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then((result) => {
        console.log("Connected!");

        connection.on("LeadCountUpdate", (amount) => {
          setLeadCount(amount);
        });
      })
      .catch((e) => console.log("Connection failed: ", e));

    return () => {
      connection.stop();
    };
  }, []);

  return (
    <Fragment>
      {props.children}
      <Badge
        overlap="rectangular"
        color="secondary"
        badgeContent={leadCount}
        style={{ right: "-17.5px" }}
      />
    </Fragment>
  );
}
