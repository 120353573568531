// Used variables for the hook 'UseMediaQueries' from the react-responsive library
// Source: https[:]//www.npmjs.com/package/react-responsive

export const MediaQueries = {
    DesktopOrLaptop: { minDeviceWidth: 1224 },
    BigScreen: { minDeviceWidth: 1824 },
    TabletOrMobile: { maxWidth: 1224 },
    TabletOrMobileDevice: { maxDeviceWidth: 1224 },
    MobileDevice: { maxDeviceWidth: 768 },
    Portrait: { orientation: 'portrait' },
    Retina: { minResolution: '2dppx' },
}
