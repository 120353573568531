import React from "react";
import { RenterTable } from "./overview/RenterTable";

export function RenterOverview() {
  return (
    <div id="page-renter-overview">
      <div className="content-wrapper">
        <section className="content-body">
          <RenterTable />
        </section>
      </div>
    </div>
  );
}
